import PropTypes from "prop-types";

// material-ui

// project import
import Logo from "./Logo";
// import config from 'config';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ width }) => {
  return (
    <a href={import.meta.env.VITE_LANDING_PAGE_BASE_URL}>
      <Logo width={width} />
    </a>
  );
};

LogoSection.propTypes = {
  sx: PropTypes.object,
  to: PropTypes.string,
};

export default LogoSection;
