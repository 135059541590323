import { Link } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';
// material-ui
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Divider,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

const DrawerFooter = () => {
  return (
    <>
      <Divider />
      <ListItemButton sx={{ mb: 2 }} component={Link} to="/dashboard/tutorial">
        <ListItemText
          primary={
            <Typography variant="h5">
              <FormattedMessage id="Dash.Menu.help" />
            </Typography>
          }
        />
        <HelpOutlineIcon fontSize="large" />
      </ListItemButton>
    </>
  );
};

export default DrawerFooter;
