import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

// material-ui
import { Box, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project import
import DialogWrapper from '@BASE/components/Dashboard/global/DialogWrapper';
import InfoPopup from '@BASE/components/Dashboard/global/InfoPopup';
import UpgradeDialog from '@BASE/components/Dashboard/global/UpgradeDialog';
import Drawer from './layout/Drawer';
import Header from './layout/Header';

import { useToastContext } from '@BASE/store/contexts';
import { useLoaderContext } from '@BASE/store/contexts/loader';
import { useUpgradeContext } from '@BASE/store/contexts/openUpgrade';
import { setSections } from '@BASE/store/reducers/auth';
import { openDrawer } from '@BASE/store/reducers/menu';

import {
  setAssistantName,
  setContextFields,
  setContexts,
  setCurrentContext,
  setIsConnectedToLinkedIn,
} from '@BASE/store/reducers/contexts';
import { globalError } from '@BASE/utils/actions/errors';
import { save_clicks } from '@BASE/utils/actions/save_actions';
import { client } from '@BASE/utils/client';
import { getToken } from '@BASE/utils/token';
import menuItems from '../../menu-items';
import {
  setAuthorizationData,
  setDifferenceInDays,
  setHaveToUpgradeSoon,
  setIsExpired,
  setIsFirstConnection,
} from '../../store/reducers/auth';
import { checkIfUpgradeNotificationConditionsAreMet } from '../../utils/checkUpgrade';
import {
  setCurrentExtensionContext,
  setExtensionContexts,
} from '../../store/reducers/contexts';

client.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${getToken()}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setLoader } = useLoaderContext();
  const { showToast, popupOpen, setPopupOpen, popupContent, setPopupContent } =
    useToastContext();
  const { openUpgradeDialog, setOpenUpgradeDialog, setUpgradeMessage } =
    useUpgradeContext();

  const { reloadContexts } = useSelector((state) => state.contexts);
  const { sections } = useSelector((state) => state.auth);

  const [extensionFirstTime, setExtensionFirstTime] = useState(true);

  useEffect(() => {
    if (searchParams.get('ext') === 'true' && !extensionFirstTime) {
      setPopupContent({
        title: intl.formatMessage({ id: 'Popup.ext_context.completed.title' }),
        message: intl.formatMessage({
          id: 'Popup.ext_context.completed.message',
        }),
      });
      setPopupOpen(true);
      searchParams.delete('ext');
      setSearchParams(searchParams);
    }
    if (searchParams.get('upgrade') === 'true') {
      setUpgradeMessage(
        intl.formatMessage({ id: 'Popup.ext_linkedin.upgrade.message' })
      );
      setOpenUpgradeDialog(true);
      searchParams.delete('upgrade');
      setSearchParams(searchParams);
    }
    if (searchParams.get('manual-upgrade') === 'true') {
      setOpenUpgradeDialog(true);
      searchParams.delete('manual-upgrade');
      setSearchParams(searchParams);
    }
  }, [
    extensionFirstTime,
    intl,
    searchParams,
    setOpenUpgradeDialog,
    setPopupContent,
    setPopupOpen,
    setSearchParams,
    setUpgradeMessage,
  ]);

  // ======= LOAD USER CONTEXT ========= //
  const handleGetContexts = useCallback(async () => {
    try {
      setLoader(true);
      let response = await client.get(
        `/contexts`,
        {},
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );

      if (response.data.data.contexts.length === 0) {
        navigate('/welcome', { replace: true });
      }
      const mainContext = response?.data?.data?.contexts;
      dispatch(setContexts(mainContext));
      dispatch(setCurrentContext(mainContext[0]));

      const cmContext = response?.data?.data?.extensionContext;
      dispatch(setExtensionContexts(cmContext));
      const selectedContext = cmContext?.filter((context) => {
        return context?.isSelected === true;
      });

      dispatch(
        setCurrentExtensionContext(
          selectedContext[0] ? selectedContext : [cmContext[0]]
        )
      );
      dispatch(setAssistantName(response.data.data.assistantName));
      let linkedInInfos = response.data.data._linkedin_token_info;
      dispatch(
        setIsConnectedToLinkedIn(
          linkedInInfos !== undefined &&
            linkedInInfos !== null &&
            Object.keys(linkedInInfos).length > 0
        )
      );

      // ======= EXTENSION CONTEXT HAVE TO REVIEW THE LOGIC=========
      let extCont = response?.data?.data?.extensionContext;
      if (
        mainContext.length !== 0 &&
        ((searchParams.get('ext') === 'true' && extCont.length === 0) ||
          extCont.length === 0)
      ) {
        if (sections.includes('CONTENUS')) {
          if (location.pathname === '/dashboard/contents/community-manager/')
            return;
          navigate('/dashboard/contents/community-manager/?ext=true', {
            replace: true,
          });
          localStorage.setItem('extensionContext', false);
        }
      } else {
        setExtensionFirstTime(false);
        localStorage.setItem('extensionContext', true);
      }
    } catch (err) {
      globalError(err, showToast, intl, navigate);
    } finally {
      setLoader(false);
    }
  }, [dispatch, intl, navigate, searchParams, showToast]);

  const handleGetContextsFields = useCallback(async () => {
    try {
      setLoader(true);
      let response = await client.get('/context-field/get-all');
      dispatch(setContextFields(response.data.data));
    } catch (err) {
      globalError(err, showToast, intl, navigate);
    } finally {
      setLoader(false);
    }
  }, [dispatch, intl, setLoader, showToast]);

  const getTitle = useCallback(
    (id) => {
      if (intl.messages[id] !== undefined) {
        return intl.formatMessage({ id });
      } else {
        return id;
      }
    },
    [intl]
  );

  const handleGetUserAuthorization = useCallback(async () => {
    try {
      setLoader(true);
      let response = await client.get('/users/get-authorization');
      dispatch(setAuthorizationData(response?.data?.data));

      if (
        response?.data?.data?._numberOfConnection === 1 &&
        !response?.data?.data?.userPlan?._plan_id
      ) {
        dispatch(setIsFirstConnection(true));
      }

      if (response?.data?.data?._default_renewal_date) {
        if (
          checkIfUpgradeNotificationConditionsAreMet(
            response?.data?.data?._default_renewal_date
          ).value
        ) {
          dispatch(setHaveToUpgradeSoon(true));
          dispatch(
            setDifferenceInDays(
              checkIfUpgradeNotificationConditionsAreMet(
                response?.data?.data?._default_renewal_date
              ).differenceInDays
            )
          );
        }
      } else if (response?.data?.data?.userPlan?._renewal_date) {
        if (
          checkIfUpgradeNotificationConditionsAreMet(
            response?.data?.data?.userPlan?._renewal_date
          ).value
        ) {
          dispatch(setHaveToUpgradeSoon(true));
          dispatch(
            setDifferenceInDays(
              checkIfUpgradeNotificationConditionsAreMet(
                response?.data?.data?._default_renewal_date
              ).differenceInDays
            )
          );
        }
      }

      if (response?.data?.data?.userPlan?._plan_id?.features) {
        if (response?.data?.data?.userPlan?._renewal_date) {
          let defaultDate = new Date(
            response?.data?.data?.userPlan?._renewal_date
          ).getTime();
          let today = new Date().getTime();
          if (defaultDate > today) {
            const sections = [
              ...response.data.data.userPlan._plan_id.features.map(
                (feature) => feature.code
              ),
            ];
            sections.push('REFERRAL');
            dispatch(setSections(sections));
            dispatch(setIsExpired(false));
          } else {
            dispatch(setIsExpired(true));
            dispatch(setSections([]));
          }
        }
      } else if (response?.data?.data?._default_renewal_date) {
        let defaultDate = new Date(
          response?.data?.data?._default_renewal_date
        ).getTime();
        let today = new Date().getTime();
        if (defaultDate > today) {
          dispatch(setIsExpired(false));
          const theMenu = menuItems(getTitle).items.filter((item) => {
            return item.code !== 'REFERRAL';
          });
          const sections = theMenu.map((item) => {
            return item.code;
          });
          dispatch(setSections(sections));
        } else {
          dispatch(setSections([]));
          dispatch(setIsExpired(true));
        }
      } else {
        const theMenu = menuItems(getTitle).items.filter((item) => {
          return item.code !== 'REFERRAL';
        });
        const sections = theMenu.map((item) => {
          return item.code;
        });
        dispatch(setSections(sections));
      }
    } catch (err) {
      globalError(err, showToast, intl, setOpenUpgradeDialog);
    } finally {
      setLoader(false);
    }
  }, [dispatch, getTitle, intl, setOpenUpgradeDialog, showToast]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await handleGetContextsFields();
        await handleGetContexts();
        await handleGetUserAuthorization();
      } catch (err) {
        globalError(err, showToast, intl, navigate);
      }
    };

    fetchData();
  }, [
    location,
    searchParams,
    reloadContexts,
    handleGetContextsFields,
    handleGetContexts,
    handleGetUserAuthorization,
    setLoader,
    showToast,
    intl,
    navigate,
  ]);

  useEffect(() => {
    const buttons = document.querySelectorAll('button');
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].addEventListener('click', function (e) {
        save_clicks({ text: e.target.innerText, origin: e.target.baseURI });
      });
    }
    const as = document.querySelectorAll('a');
    for (let i = 0; i < as.length; i++) {
      as[i].addEventListener('click', function (e) {
        save_clicks({ text: e.target.innerText, origin: e.target.baseURI });
      });
    }
    // }, 1000)
  }, [location]);

  // ======= DRAWER RESPONSIVE ========= //
  const { drawerOpen } = useSelector((state) => state.menu);
  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };
  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));
  }, [dispatch, matchDownLG]);
  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
  }, [drawerOpen, open]);

  return (
    <>
      <InfoPopup
        open={popupOpen}
        setOpen={setPopupOpen}
        content={popupContent}
      />
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Header open={open} handleDrawerToggle={handleDrawerToggle} />
        <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
        <Box
          component="main"
          sx={{ width: '100%', flexGrow: 0, p: { xs: 2, sm: 3 } }}
        >
          <Toolbar />
          {/* <Breadcrumbs navigation={navigation} title sx={{ marginBottom: '20px' }} /> */}
          <Outlet />
        </Box>
      </Box>
      <DialogWrapper
        open={openUpgradeDialog}
        setOpen={setOpenUpgradeDialog}
        maxWidth="xl"
      >
        <UpgradeDialog />
      </DialogWrapper>
    </>
  );
};

export default MainLayout;
