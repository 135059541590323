export const checkIfUpgradeNotificationConditionsAreMet = (renewalDate) => {
  const differencesToUpgrade = [1, 3, 5];
  const today = new Date();
  const renewalDateObjet = new Date(renewalDate);
  if (today.getTime() < renewalDateObjet.getTime()) {
    const differenceInDays = Math.floor(
      (renewalDateObjet.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)
    );
    if (differencesToUpgrade.indexOf(differenceInDays) !== -1) {
      return { value: true, differenceInDays };
    }
    return { value: false };
  }
  return { value: false };
};
