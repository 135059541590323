import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

// project import
import Loadable from '@BASE/components/Loader/Loadable';
import AuthorizedRoute from './AuthorizedRoute';
import ProtectedRoute from './ProtectedRoute';

// const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const Pricing = Loadable(lazy(() => import('../pages/LandingPage/Pricing')));
const Privacy = Loadable(lazy(() => import('../pages/LandingPage/Privacy')));
const PaymentRedirect = Loadable(
  lazy(() => import('../pages/LandingPage/PaymentRedirect'))
);
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(
  lazy(() => import('../pages/authentication/Register'))
);
const ConfirmMail = Loadable(
  lazy(() => import('../pages/authentication/ConfirmMail'))
);
const ForgetPass = Loadable(
  lazy(() => import('../pages/authentication/ForgetPass'))
);

const OnBoarding = Loadable(lazy(() => import('../pages/OnBoarding')));

import Error from '../pages/ErrorPage';
import RouteError from '../pages/ErrorPage/RouteError';

const LinkedInSuccess = Loadable(
  lazy(() => import('../pages/LinkedInSuccess'))
);

// render - dashboard
import DashBoard from '../pages/Dashboard';
import Subscription from '../pages/Dashboard/panels/Subscription';
import NotificationPage from '../pages/Dashboard/panels/Notification';
import Referral from '../pages/Dashboard/panels/Referral';
const Welcome = Loadable(
  lazy(() => import('../pages/Dashboard/panels/Welcome'))
);
const Test = Loadable(lazy(() => import('../pages/Dashboard/panels/Test')));
const Tutorial = Loadable(
  lazy(() => import('../pages/Dashboard/panels/Tutorial'))
);
const Profile = Loadable(
  lazy(() => import('../pages/Dashboard/panels/Profile'))
);
const Context = Loadable(
  lazy(() => import('../pages/Dashboard/panels/Context'))
);
const Prospection = Loadable(
  lazy(() => import('../pages/Dashboard/panels/Prospection'))
);
const Marketing = Loadable(
  lazy(() => import('../pages/Dashboard/panels/Marketing'))
);
const CommunityManager = Loadable(
  lazy(() => import('../pages/Dashboard/panels/Contents/CommunityManager'))
);
const SavedContents = Loadable(
  lazy(() => import('../pages/Dashboard/panels/Contents/SavedContents'))
);
const ContentIdeas = Loadable(
  lazy(() => import('../pages/Dashboard/panels/Contents/ContentIdeas'))
);
const Ghostwriter = Loadable(
  lazy(() => import('../pages/Dashboard/panels/Contents/Ghostwriter'))
);
const Seo = Loadable(
  lazy(() => import('../pages/Dashboard/panels/Contents/Seo'))
);
const EReputation = Loadable(
  lazy(() => import('../pages/Dashboard/panels/Ereputation'))
);
const EmailAccounts = Loadable(
  lazy(() => import('../pages/Dashboard/panels/EmailAccounts/EmailAccounts'))
);
const SettingsPage = Loadable(lazy(() => import('../pages/Dashboard/panels/EmailAccounts/SettingsPage')));

const Scheduling = Loadable(
  lazy(() => import('../pages/Dashboard/panels/Scheduling'))
);
const Analysis = Loadable(
  lazy(() => import('../pages/Dashboard/panels/Analysis'))
);
const Automation = Loadable(
  lazy(() => import('../pages/Dashboard/panels/Automation'))
);
const Ecom = Loadable(lazy(() => import('../pages/Dashboard/panels/Ecom')));
const Campaigns = Loadable(
  lazy(() => import('../pages/Dashboard/panels/Campaigns'))
);




const Prospects = Loadable(
  lazy(() => import('../pages/Dashboard/panels/Prospects'))
);

export default function TestRouter() {
  return useRoutes([
    { path: '/', element: <Login /> },
    { path: '/pricing', element: <Pricing /> },
    { path: '/privacy', element: <Privacy /> },
    { path: '/payment-redirect', element: <PaymentRedirect /> },
    { path: '/register', element: <Register /> },
    { path: '/register/:referralId', element: <Register /> },
    { path: '/confirm-mail', element: <ConfirmMail /> },
    { path: '/forgot-password', element: <ForgetPass /> },
    { path: '/welcome', element: <OnBoarding /> },
    { path: '/auth/linkedin/callback', element: <LinkedInSuccess /> },
    {
      path: '/dashboard',
      element: (
        <ProtectedRoute exact>
          <DashBoard />
        </ProtectedRoute>
      ),

      children: [
        { index: true, element: <Welcome /> },
        { path: 'tutorial', element: <Tutorial /> },
        { path: 'profile', element: <Profile /> },
        { path: 'test-panel', element: <Test /> },
        { path: 'context', element: <Context /> },
        { path: 'subscription', element: <Subscription /> },
        {
          path: 'prospection',
          element: (
            <AuthorizedRoute code="PROSPECTION">
              <Prospection />
            </AuthorizedRoute>
          ),
        },
        {
          path: 'marketing/:section',
          element: (
            <AuthorizedRoute code="MARKETING">
              <Marketing />
            </AuthorizedRoute>
          ),
        },
        {
          path: 'contents/community-manager',
          element: (
            <AuthorizedRoute code="CONTENUS">
              <CommunityManager />
            </AuthorizedRoute>
          ),
        },
        {
          path: 'contents/saved-contents',
          element: (
            <AuthorizedRoute code="CONTENUS">
              <SavedContents />
            </AuthorizedRoute>
          ),
        },
        {
          path: 'contents/content-ideas',
          element: (
            <AuthorizedRoute code="CONTENUS">
              <ContentIdeas />
            </AuthorizedRoute>
          ),
        },
        {
          path: 'contents/ghostwriter',
          element: (
            <AuthorizedRoute code="CONTENUS">
              <Ghostwriter />
            </AuthorizedRoute>
          ),
        },
        {
          path: 'contents/seo',
          element: (
            <AuthorizedRoute code="CONTENUS">
              <Seo />
            </AuthorizedRoute>
          ),
        },
        {
          path: 'e-reputation',
          element: (
            <AuthorizedRoute code="EREPUTATION">
              <EReputation />
            </AuthorizedRoute>
          ),
        },
        {
          path: 'email-accounts',
          element: (
            <AuthorizedRoute code="EmailAccounts">
              < EmailAccounts/>
            </AuthorizedRoute>
          ),
        },
        {
          path: 'email-accounts/SettingsPage',
          element: (
            <AuthorizedRoute code="EmailAccounts">
              <SettingsPage />
            </AuthorizedRoute>
          ),
        },
        {
          path: 'scheduling',
          element: (
            <AuthorizedRoute code="SCHEDULING">
              <Scheduling />
            </AuthorizedRoute>
          ),
        },
        {
          path: 'referral',
          element: (
            <AuthorizedRoute code="REFERRAL">
              <Referral />
            </AuthorizedRoute>
          ),
        },
        {
          path: 'analysis',
          element: (
            <AuthorizedRoute code="ANALYSES">
              <Analysis />
            </AuthorizedRoute>
          ),
        },
        {
          path: 'automation/:section',
          element: (
            <AuthorizedRoute code="AUTOMATION">
              <Automation />
            </AuthorizedRoute>
          ),
        },
        {
          path: 'ecom/:section',
          element: (
            <AuthorizedRoute code="ECOMMASTER">
              <Ecom />
            </AuthorizedRoute>
          ),
        },
        {
          path: 'campaigns/',
          element: (
            <AuthorizedRoute code="CAMPAIGNS">
              <Campaigns />
            </AuthorizedRoute>
          ),
        },
        {
          path: 'prospects',
          element: (
            <AuthorizedRoute code="PROSPECTS">
              <Prospects />
            </AuthorizedRoute>
          ),
        },
        {
          path: 'notifications',
          element: (
            <AuthorizedRoute code="PROSPECTS">
              <NotificationPage />
            </AuthorizedRoute>
          ),
        },
      ],
    },
    { path: '/error', element: <Error /> },
    { path: '*', element: <RouteError /> },
  ]);
}
