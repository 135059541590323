import { useNavigate } from 'react-router-dom';

import errorImage from '@BASE/assets/images/errors/error-404.png';
import { Box, Container, Link, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

export default function Error() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h1">404</Typography>
            <Typography variant="h6">An Error occurs</Typography>
            <Link
              component="a"
              variant="contained"
              onClick={() => navigate(-1)}
            >
              Go Back
            </Link>
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={errorImage} alt="" width={300} height={250} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
