import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ReactGA from 'react-ga4';

// material-ui
import { Button, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project import
import Logo from '@BASE/components/Logo';
import DrawerHeaderStyled from './DrawerHeaderStyled';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme();

  return (
    // only available in paid version
    <DrawerHeaderStyled theme={theme} open={open}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-around"
        sx={{ maxWidth: '100%' }}
      >
        <Logo width={60} />
        {/* <Chip
          label={"v1.0.0"}
          size="small"
          sx={{ height: 16, '& .MuiChip-label': { fontSize: '0.625rem', py: 0.25 } }}
          component="span"
        /> */}
        <Button
          component="a"
          variant="contained"
          target="_blank"
          href="https://chrome.google.com/webstore/detail/aiboostez/jmgaijifelabedfhfnpgnkcneakpgcho"
          sx={{
            borderRadius: '40px',
            px: 2,
            py: 1,
            width: 160,
            mr: { xs: 1, md: 2 },
          }}
          onClick={() => {
            ReactGA.event({
              category: 'Dashboard client button',
              action: 'Click',
              label: 'extension button',
            });
          }}
        >
          <FormattedMessage id="Dash.Header.see_extension" />
        </Button>
      </Stack>
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool,
};

export default DrawerHeader;
