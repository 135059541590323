// assets
import { ChromeOutlined, DashboardOutlined } from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined,
  ChromeOutlined
};

// ==============================|| MENU ITEMS - AUTOMATISATION ||============================== //

const automation = (getTitle) => ({
  id: 'group-automation',
  code: 'AUTOMATION',
  title: getTitle("Dash.Menu.automation"),
  icon: icons.DashboardOutlined,
  type: 'collapse',
  children: [
    {
      id: 'automation-1',
      title: getTitle("Dash.Menu.automation.onboarding"),
      type: 'item',
      url: '/dashboard/automation/1',
      icon: icons.DashboardOutlined,
      breadcrumbs: true
    },
    {
        id: 'automation-2',
        title: getTitle("Dash.Menu.automation.sav"),
        type: 'item',
        url: '/dashboard/automation/2',
        icon: icons.DashboardOutlined,
        breadcrumbs: true
    },
    {
        id: 'automation-3',
        title: getTitle("Dash.Menu.automation.prospection"),
        type: 'item',
        url: '/dashboard/automation/3',
        icon: icons.DashboardOutlined,
        breadcrumbs: true
    },
    {
        id: 'automation-4',
        title: getTitle("Dash.Menu.automation.bill"),
        type: 'item',
        url: '/dashboard/automation/4',
        icon: icons.DashboardOutlined,
        breadcrumbs: true
    },
    {
      id: 'automation-5',
      title: getTitle("Dash.Menu.automation.linkedin"),
      type: 'item',
      url: '/dashboard/automation/5',
      icon: icons.DashboardOutlined,
      breadcrumbs: true
  },
  {
    id: 'automation-6',
    title: getTitle("Dash.Menu.automation.email"),
    type: 'item',
    url: '/dashboard/automation/6',
    icon: icons.DashboardOutlined,
    breadcrumbs: true
},
  ]
});

export default automation;
 