// material-ui
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { Box, Button, useMediaQuery } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import ReactGA from 'react-ga4';

// project import
import LangButton from '@BASE/components/LangButton';
import MobileSection from './MobileSection';
import Notification from './Notification';
import Profile from './Profile';

import { useUpgradeContext } from '@BASE/store/contexts/openUpgrade';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = ({ show = true }) => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { setOpenUpgradeDialog } = useUpgradeContext(false);

  return (
    <>
      {!matchesXs && <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}></Box>}
      {/* {!matchesXs && <Search />} */}
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}

      {/* <IconButton
        component={Link}
        href="https://github.com/codedthemes/mantis-free-react-admin-template"
        target="_blank"
        disableRipple
        color="secondary"
        title="Download Free Version"
        sx={{ color: 'text.primary', bgcolor: 'grey.100' }}
      >
        <GithubOutlined />
      </IconButton> */}
      <Box>
        <Button
          variant="contained"
          onClick={() => {
            setOpenUpgradeDialog(true);
            ReactGA.event({
              category: 'Dashboard client button',
              action: 'Click',
              label: 'upgrade button',
            });
          }}
          endIcon={<WorkspacePremiumIcon />}
          sx={{ borderRadius: '50px', mx: 1 }}
        >
          <FormattedMessage id="Dash.Menu.upgrade" />
        </Button>
      </Box>
      {show && (
        <>
          <LangButton />
          <Notification />
          {!matchesXs && <Profile />}
          {matchesXs && <MobileSection />}
        </>
      )}
    </>
  );
};

export default HeaderContent;
