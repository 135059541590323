// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  prospectsLists: [],
  prospectsList: {},
  campaigns: [],
  campaign: {},
  reloadProspects: 0,
  reloadProspectsLists: 0,
  reloadCampaigns: 0,
};


const campaigns = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    setProspectsLists(state, action) {
      state.prospectsLists = action.payload;
    },
    setProspectsList(state, action) {
      state.prospectsList = action.payload;
    },
    setCampaigns(state, action) {
      state.campaigns = action.payload;
    },
    setCampaign(state, action) {
      state.campaign = action.payload;
    },
    setReloadProspects(state, action) {
      state.reloadProspects = state.reloadProspects + 1;
    },
    setReloadProspectsLists(state, action) {
      state.reloadProspectsLists = state.reloadProspectsLists + 1;
    },
    setReloadCampaigns(state, action) {
      state.reloadCampaigns = state.reloadCampaigns + 1;
    }
  },
});

export default campaigns.reducer;

export const { setProspectsLists, setProspectsList, setReloadProspects, setReloadProspectsLists,
  setCampaigns, setCampaign, setReloadCampaigns } =
  campaigns.actions;
