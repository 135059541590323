export const featureSortForTable = (packs) => {
  let allDetails = packs[2]?.features;
  allDetails = allDetails?.map((detail) => ({
    featureName: detail?.code,
    featureDetails: detail?.details?.map((dea) => {
      return {
        name: localStorage.getItem('lang') === 'en' ? dea.en : dea.fr,
        starterState: false,
        leadsState: false,
        godModState: true,
      };
    }),
  }));

  let starterDetails = packs[0]?.features;
  if (starterDetails?.length > 0) {
    starterDetails = starterDetails?.map((dea) => dea.code);
  }

  let leadDetails = packs[1]?.features;
  if (leadDetails?.length > 0) {
    leadDetails = leadDetails?.map((dea) => dea.code);
  }

  allDetails = allDetails?.map((detail) => {
    return {
      featureName: detail?.featureName,
      featureDetails: detail.featureDetails.map((deal) => {
        return leadDetails.includes(detail.featureName)
          ? { ...deal, leadsState: true }
          : deal;
      }),
    };
  });

  allDetails = allDetails?.map((detail) => {
    return {
      featureName: detail?.featureName,
      featureDetails: detail?.featureDetails.map((deal) => {
        return starterDetails?.includes(detail.featureName)
          ? { ...deal, starterState: true }
          : deal;
      }),
    };
  });
  return allDetails;
};
