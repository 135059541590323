import PropTypes from 'prop-types';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useToastContext } from '@BASE/store/contexts';

// material-ui
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

// project import
import { useUpgradeContext } from '@BASE/store/contexts/openUpgrade';
import NavItem from './NavItem';

const textColor = 'text.primary';

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

const NavCollapse = ({ item }) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;
  const { setOpenUpgradeDialog, setUpgradeMessage } = useUpgradeContext();

  const Icon = item.icon;
  const itemIcon = item.icon ? (
    <Icon style={{ fontSize: drawerOpen ? 15 : 25 }} />
  ) : (
    false
  );

  const navCollapse = item.children?.map((menuItem) => {
    switch (menuItem.type) {
      case 'collapse':
        return (
          <Typography
            key={menuItem.id}
            variant="caption"
            color="error"
            sx={{ p: 2.5 }}
          >
            collapse - only available in paid version
          </Typography>
        );
      case 'item':
        return <NavItem key={menuItem.id} item={menuItem} level={1} />;
      default:
        return (
          <Typography
            key={menuItem.id}
            variant="h6"
            color="error"
            align="center"
          >
            Fix - Collapse or Items
          </Typography>
        );
    }
  });

  const { showToast } = useToastContext();

  const handleClickOpen = () => {
    if (item.notAllowed) {
      setUpgradeMessage(intl.formatMessage({ id: 'Toast.upgrade.feature' }));
      setOpenUpgradeDialog(true);
      showToast({
        severity: 'info',
        message: intl.formatMessage({
          id: 'features.authorisation',
        }),
      });
    } else setOpen(!open);
  };

  return (
    <>
      <ListItemButton
        onClick={handleClickOpen}
        sx={{
          ...(open && {
            elevation: 1,
            bgcolor: 'secondary.lighter',
            borderStyle: 'groove',
            border: 2,
            borderColor: 'primary.light',
            borderRadius: 2,
            mb: 2,
          }),
          ...(!open && { mb: 0 }),
        }}
      >
        {item.icon && (
          <ListItemIcon
            sx={{
              minWidth: 28,
              color: textColor,
              ...(!drawerOpen && {
                borderRadius: 1.5,
                width: 36,
                height: 36,
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                  bgcolor: 'secondary.lighter',
                },
              }),
            }}
          >
            {itemIcon}
          </ListItemIcon>
        )}
        <ListItemText
          primary={<Typography variant="h5">{item.title}</Typography>}
        />
        {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
        {item.notAllowed && <WorkspacePremiumIcon color="primary" />}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <List sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}>
          {navCollapse}
        </List>
      </Collapse>
    </>
  );
};

NavCollapse.propTypes = {
  item: PropTypes.object,
};

export default NavCollapse;
