import { Box, Button, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ReferralLinkCard from '../../../../components/Dashboard/Referral/ReferralLinkCard';
import ReferralPersonCountCard from '../../../../components/Dashboard/Referral/ReferralPersonCountCard';
import ReferralPriceCard from '../../../../components/Dashboard/Referral/ReferralPriceCard';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function Referral() {
  const intl = useIntl();
  const { authorizationData } = useSelector((state) => state.auth);
  const [ispartener, setIsPatener] = useState(false);

  useEffect(() => {
    const authorizedPartener = () => {
      if (authorizationData?.userPlan?._plan_id?.name === 'God Mod') {
        const monthDuration = 60 * 60 * 24 * 31 * 1000;

        const startedDate = new Date(
          authorizationData?.userPlan?._started_date
        );
        const renewalDate = new Date(
          authorizationData?.userPlan?._renewal_date
        );

        const subscriptionDuration = renewalDate - startedDate;

        if (subscriptionDuration > monthDuration) {
          return setIsPatener(true);
        }
      }
      return setIsPatener(false);
    };
    authorizedPartener();
  }, [
    authorizationData?.userPlan?._plan_id?.name,
    authorizationData?.userPlan?._renewal_date,
    authorizationData?.userPlan?._started_date,
  ]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={'100%'}
      height={'100%'}
      flexDirection={'column'}
      my={'auto'}
      gap={4}
    >
      {/* Heading */}
      <Stack alignItems={'center'} width="100%" gap={1}>
        <Box width={'100%'} textAlign={'center'}>
          <Typography variant="h2" fontWeight={'bold'} display={'inline-block'}>
            {intl.formatMessage({ id: 'dash.referral.h1.1' })}
          </Typography>
          <Typography
            variant="h2"
            fontWeight={'bold'}
            color={'primary.main'}
            display={'inline-block'}
          >
            &nbsp;40%
          </Typography>
          <Typography variant="h2" fontWeight={'bold'} display={'inline-block'}>
            &nbsp;{intl.formatMessage({ id: 'dash.referral.h1.3' })}
          </Typography>
          <Typography
            variant="h2"
            fontWeight={'bold'}
            display={'inline-block'}
            color={'primary.main'}
          >
            &nbsp;{intl.formatMessage({ id: 'dash.referral.h1.4' })}
          </Typography>
          <Typography variant="h2" fontWeight={'bold'} display={'inline-block'}>
            &nbsp;!
          </Typography>
        </Box>
        <Typography variant="h4" fontWeight={'bold'} textAlign={'center'}>
          {intl.formatMessage({ id: 'dash.referral.h2.1' })}
        </Typography>
      </Stack>

      {/* Statistics */}
      {authorizationData && (
        <Box
          width={'100%'}
          display={'flex'}
          flexWrap={'wrap'}
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent={'center'}
          alignItems={'center'}
          gap={5}
          mt={10}
        >
          <ReferralPriceCard
            amount={authorizationData?._referalProgram?.amount || 0}
          />
          <ReferralPersonCountCard
            title={intl.formatMessage({
              id: 'dash.referral.stats.affiliate.text',
            })}
            affiliateNumber={
              authorizationData?._referalProgram?.paymentCount || 0
            }
          />
          <ReferralPersonCountCard
            title={intl.formatMessage({
              id: 'dash.referral.stats.affiliate.nobuy.text',
            })}
            affiliateNumber={authorizationData?._referalProgram?.count || 0}
          />

          <ReferralPersonCountCard
            percentSymbol
            title={intl.formatMessage({
              id: 'dash.referral.stats.conversion.text',
            })}
            affiliateNumber={
              (authorizationData?._referalProgram?.paymentCount /
                authorizationData?._referalProgram?.count) *
                100 || 0
            }
          />
          <ReferralLinkCard referralId={authorizationData?._referralId || ''} />

          {ispartener && (
            <Stack alignItems={'center'} width="100%" gap={1}>
              <Box width={'100%'} textAlign={'center'}>
                <Typography
                  variant="h2"
                  fontWeight={'bold'}
                  display={'inline-block'}
                >
                  {intl.formatMessage({ id: 'dash.referral.partener.1' })}
                </Typography>
              </Box>
              <Typography variant="h4" fontWeight={'bold'} textAlign={'center'}>
                {intl.formatMessage({ id: 'dash.referral.partener.2' })}
              </Typography>
              <Link
                to="https://calendly.com/dabanguibesoumaila/partenaires-aiboostez"
                style={{
                  textDecoration: 'none',
                }}
                target="_blank"
              >
                <Button
                  variant="contained"
                  tabIndex={-1}
                  sx={{
                    width: '60%',
                    minWidth: '300px',
                    mt: 5,
                    py: 2,
                    fontWeight: 'bold',
                    borderRadius: '35px',
                  }}
                >
                  {intl.formatMessage({ id: 'dash.referral.partener.button' })}
                </Button>
              </Link>
            </Stack>
          )}
        </Box>
      )}
    </Box>
  );
}

export default Referral;
