import { createTheme } from '@mui/material/styles';
import { frFR } from '@mui/material/locale';
import { frFR as gridFrFR, enUS as gridEnUS } from '@mui/x-data-grid';



const myPrimary = '#4be40d';
const mySecondary = '#545454';

const myPrimaryL = '#4be40d';
const mySecondaryL = '#E0E0E0';

const oldbackground = 'rgb(15, 22, 36)';


export const darkTheme = (locale, gridLocale, dateLocale) => createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 640,
			md: 768,
			lg: 1024,
			xl: 1280,
		},
	},
	palette: {
		mode: 'dark',
		primary: {
			main: myPrimary,
		},
		secondary: {
			main: mySecondary,
		},
		background: {
			default: 'rgb(15, 22, 36)',
			paper: '#424242',
		},
	},
	typography: {
		subtitle1: {
			fontSize: 14,
		},
		subtitle2: {
			fontSize: 10,
		},
		body1: {
			fontSize: 16,
		},
		body2: {
			fontSize: 14,
		},
		button: {
			fontStyle: 'bold',
			fontSize: 14,
		},
		caption: {
			fontSize: 10,
		},
	},
	// components: {
	// 	MuiTab: {
	// 		styleOverrides: {
	// 			"root": {
	// 				transition: '0.3s ease-in-out',
	// 				"&.Mui-selected": {
	// 					background: myPrimary,
	// 					color: 'rgb(15, 22, 36)',
	// 				}
	// 			}
	// 		},
	// 	},
	// },
	customShadows: {
		button: `0 2px #0000000b`,
		text: `0 -1px 0 rgb(0 0 0 / 12%)`,
		z1: `0px 2px 8px #d3e8d9 , 0.15)}`
		// only available in paid version
	},
	
}, locale, gridLocale, dateLocale);


export const lightTheme = (locale) => createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 640,
			md: 768,
			lg: 1024,
			xl: 1280,
		},
	},
	palette: {
		mode: 'light',
		primary: {
			main: myPrimaryL,
		},
		secondary: {
			main: mySecondaryL,
		},
		background: {
			default: "#F5F5F5",
			paper: "#FFFFFF",
		},
	},
	typography: {
		subtitle1: {
			fontSize: 14,
		},
		subtitle2: {
			fontSize: 12,
		},
		body1: {
			fontSize: 16,
		},
		body2: {
			fontSize: 14,
		},
		button: {
			fontStyle: 'bold',
			fontSize: 16,
		},
		caption: {
			fontSize: 10,
		},
	},
	components: {
		MuiTab: {
			styleOverrides: {
				"root": {
					transition: '0.3s ease-in-out',
					"&.Mui-selected": {
						background: myPrimaryL,
						color: '#FFFFFF',
					}
				}
			},
		},
	},
	
}, locale, gridLocale);

