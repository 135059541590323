import { FormControl, FormLabel, Switch } from '@chakra-ui/react';
import { usePricing } from './pricing.hook';
import { useEffect } from 'react';

function PricingDurationSwitcher({
  pricingTimeDuration,
  pricingTimeDurationReal,
  isChecked,
  onCheck,
}) {
  const { setDuration } = usePricing();
  useEffect(() => {
    setDuration(pricingTimeDurationReal);
  }, [setDuration, pricingTimeDurationReal]);

  console.log(pricingTimeDurationReal);

  return (
    <FormControl
      display="flex"
      justifyContent="center"
      alignItems="center"
      px={5}
    >
      <FormLabel
        htmlFor="email-alerts"
        fontSize="4xl"
        py="auto"
        mb="0"
        fontWeight="semibold"
      >
        {pricingTimeDuration}
      </FormLabel>
      <Switch
        colorScheme="brand"
        id="email-alerts"
        size="lg"
        isChecked={isChecked}
        onChange={() => onCheck(pricingTimeDuration)}
      />
    </FormControl>
  );
}

export default PricingDurationSwitcher;
