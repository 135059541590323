// import socket from "@BASE/socket/socket"
import { getTokenEmail} from '@BASE/utils/token';


export function  save_actions(type){
    let actions = JSON.parse(localStorage.getItem('actions'));
    if(actions == null) actions = [];
    actions.push({
      action_type: type,
      action_source: "DASHBOARD", 
      action_date: Date.now(),
    });
    localStorage.setItem('actions', JSON.stringify(actions)); 
}

export function  save_clicks({text, origin}){
  // console.log(text, origin)
  if(text){
    const body = {
        email: getTokenEmail(),
        text: text,
        source: "DASHBOARD", 
        date: Date.now(),
        origin: origin
      };
    // socket.emit("click_event", body)
  }
  // let clicks = JSON.parse(localStorage.getItem('clicks'));
  // if(clicks == null) clicks = [];
  // clicks.push({
  //   click_text: text,
  //   click_source: "DASHBOARD", 
  //   click_date: Date.now(),
  //   click_origin: origin
  // });
  // localStorage.setItem('clicks', JSON.stringify(clicks)); 
}