import { getToken } from '@BASE/utils/token';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const checkUserToken = useCallback(() => {
    const userToken = getToken();
    if (!userToken || userToken === 'undefined' || userToken === 'no-token') {
      setIsLoggedIn(false);
      const queryParam1 = searchParams.get('ext') === 'true' ? '?ext=true' : '';
      const queryParam2 =
        searchParams.get('upgrade') === 'true' ? '?upgrade=true' : '';
      const queryParams = queryParam1 + queryParam2;
      navigate(`/${queryParams}`, { replace: true });
    }
    setIsLoggedIn(true);
  }, [navigate, searchParams]);

  useEffect(() => {
    checkUserToken();
  }, [checkUserToken, isLoggedIn]);

  return (
    <>
      {isLoggedIn && children}
      {/* {!isLoggedIn && <Navigate to="/" />} */}
    </>
  );
};
export default ProtectedRoute;
