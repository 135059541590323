// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import auth from './auth';
import register from './register';
import onboarding from './onboarding';
import contexts from './contexts'
import outputs from './outputs'
import contents from './contents'
import scheduling from './scheduling'
import campaigns from './campaigns'

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ menu, auth, register, onboarding, contexts, outputs, contents, scheduling, campaigns });

export default reducers;
