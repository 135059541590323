import CssBaseline from '@mui/material/CssBaseline';
import { enUS, frFR } from '@mui/material/locale';
import { ThemeProvider } from '@mui/material/styles';
import { enUS as gridEnUS, frFR as gridFrFR } from '@mui/x-data-grid';
import {
  enUS as dateEnUS,
  frFR as dateFrFR,
} from '@mui/x-date-pickers/locales';
import { useContext, useEffect } from 'react';

// project import
import LoaderOverlay from '@BASE/components/Loader/LoaderOverlay';
import ToastNotification from '@BASE/components/ToastNotification';
import MyRouter from '@BASE/routes/MyRouter';
import { ToastContextProvider } from '@BASE/store/contexts';
import { LangContext } from '@BASE/store/contexts/LangWrapper';
import { LoaderContextProvider } from '@BASE/store/contexts/loader';
import { UpgradeContextProvider } from '@BASE/store/contexts/openUpgrade';
import { darkTheme } from '@BASE/styles/themes/theme';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
const TRACKING_ID = 'G-VPB6VZKK2X';

const App = () => {
  const langContext = useContext(LangContext);
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    // Send pageview with a custom path
    ReactGA.send({
      hitType: 'pageview',
      page: `${location.pathname}${location.search}`,
      title: `Dashboard client : ${location.pathname}${location.search}`,
    });
  }, [location.pathname, location.search]);

  let locale;
  let gridLocale;
  let dateLocale;
  switch (langContext.locale) {
    case 'fr':
      locale = frFR;
      gridLocale = gridFrFR;
      dateLocale = dateFrFR;
      break;
    case 'en':
      locale = enUS;
      gridLocale = gridEnUS;
      dateLocale = dateEnUS;
      break;
    default:
      locale = frFR;
      gridLocale = gridFrFR;
      dateLocale = dateFrFR;
      break;
  }

  return (
    <>
      <ThemeProvider theme={darkTheme(locale, gridLocale, dateLocale)}>
        <CssBaseline />
        <LoaderContextProvider>
          <ToastContextProvider>
            <UpgradeContextProvider>
              <LoaderOverlay />
              <ToastNotification />
              <MyRouter />
            </UpgradeContextProvider>
          </ToastContextProvider>
        </LoaderContextProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
