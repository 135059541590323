import { CheckIcon, SmallCloseIcon } from "@chakra-ui/icons";
import { useIntl } from "react-intl";

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
} from "@chakra-ui/react";
import { usePricing } from "./pricing.hook";

function TableComponent({ featureInfo, packs }) {
  const { onCheckout } = usePricing();
  const intl = useIntl();
  return (
    <TableContainer w="100%" mb={2} textAlign="center">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th fontSize="lg" color="white" py={8}>
              {/* {$t("Pricing.feature.title")} */}
              {intl.formatMessage({ id: `Pricing.feature.title` })}
            </Th>
            {packs?.map((pack) => (
              <Th
                key={pack?.name}
                textAlign="center"
                fontSize="lg"
                color="white"
              >
                {pack?.name}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {featureInfo?.map((feature) => (
            <>
              <Tr w="100%" key={feature?.featureName} bg="brand.500">
                <Td fontWeight="bold" color="black" fontSize="lg">
                  {feature?.featureName?.toUpperCase()}
                </Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
              </Tr>

              {feature?.featureDetails?.map((feat) => (
                <Tr key={feat?.name}>
                  <Td>{feat?.name}</Td>
                  <Td textAlign="center">
                    {feat.starterState ? (
                      <CheckIcon fontSize="lg" color="brand.500" />
                    ) : (
                      <SmallCloseIcon fontSize="lg" color="red.500" />
                    )}
                  </Td>
                  <Td textAlign="center">
                    {feat?.leadsState ? (
                      <CheckIcon fontSize="lg" color="brand.500" />
                    ) : (
                      <SmallCloseIcon fontSize="lg" color="red.500" />
                    )}
                  </Td>
                  <Td textAlign="center">
                    {feat?.godModState ? (
                      <CheckIcon fontSize="lg" color="brand.500" />
                    ) : (
                      <SmallCloseIcon fontSize="lg" color="red.500" />
                    )}
                  </Td>
                </Tr>
              ))}
            </>
          ))}
        </Tbody>
        <Tfoot>
          <Tr>
            <Td></Td>
            {packs?.map((pack) => (
              <Td key={pack?.name} textAlign="center">
                <Button
                  colorScheme="brand"
                  color="black"
                  variant="solid"
                  p={7}
                  mx="auto"
                  size="lg"
                  onClick={() => onCheckout(pack?.lookup_key)}
                >
                  {intl.formatMessage({ id: `Pricing.select.button` })}
                </Button>
              </Td>
            ))}
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
}

export default TableComponent;
