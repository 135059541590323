import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useToastContext } from "@BASE/store/contexts";

export default function AlertDialog() {
  const { popupOpen, setPopupOpen, popupContent } = useToastContext();

  const handleClose = () => {
    setPopupOpen(false);
  };
  const handleOk = () => {
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={popupOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ elevation: 0 }}
      >
        <DialogTitle id="alert-dialog-title">{popupContent.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {popupContent.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleOk}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
