// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  outputs: [],
  output: {
    prospection: "",
    customer_analysis: "",
    sales_webinars: "",
    closing: "",
    strat_marketing: "",
    analysis: "",
    ghostwriter: "",
    contentidea: "",
    saved_content: "",
    seo: "",
    seo2: "",
  },
  input: {
    prospection: "",
    customer_analysis: "",
    sales_webinars: "",
    closing: "",
    strat_marketing: "",
    analysis: [],
    ghostwriter: "",
    contentidea: "",
    saved_content: "",
    seo: "",
    seo2: "",
  },
};

// ==============================|| SLICE - PROMPT CONTEXT ||============================== //

const outputs = createSlice({
  name: "outputs",
  initialState,
  reducers: {
    setOutput(state, action) {
      state.output = action.payload;
    },
    setOutputProspection(state, action) {
      state.output.prospection = action.payload;
    },
    setOutputClientAnalyse(state, action) {
      state.output.customer_analysis = action.payload;
    },
    setOutputSalesWebinars(state, action) {
      state.output.sales_webinars = action.payload;
    },
    setOutputClosing(state, action) {
      state.output.closing = action.payload;
    },
    setOutputStratMarketing(state, action) {
      state.output.strat_marketing = action.payload;
    },
    setOutputAnalysis(state, action) {
      state.output.analysis = action.payload;
    },
    setOutputGhostwriter(state, action) {
      state.output.ghostwriter = action.payload;
    },
    setOutputContentIdea(state, action) {
      state.output.contentidea = action.payload;
    },
    setOutputSavedContent(state, action) {
      state.output.saved_content = action.payload;
    },
    setOutputSeo(state, action) {
      state.output.seo = action.payload;
    },
    setOutputSeo2(state, action) {
      state.output.seo = action.payload;
    },
    addOutputs(state, action) {
      state.outputs = state.outputs.push(action.payload);
    },
    setInput(state, action) {
      state.input = {...(state.input), ...(action.payload)};
    },
    setAnalysisInput(state, action) {
      state.input.analysis = action.payload;
    }
  },
});

export default outputs.reducer;

export const {
  setOutput,
  addOutputs,
  setOutputProspection,
  setOutputClientAnalyse,
  setOutputSalesWebinars,
  setOutputClosing,
  setOutputAnalysis,
  setOutputGhostwriter,
  setOutputContentIdea,
  setOutputStratMarketing,
  setOutputSavedContent,
  setOutputSeo,
  setOutputSeo2,
  setInput,
  setAnalysisInput
} = outputs.actions;
