import { useIntl } from "react-intl";
import { CheckIcon } from "@chakra-ui/icons";
import { LangContext } from "@BASE/store/contexts/LangWrapper";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  HStack,
  Heading,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { usePricing } from "./pricing.hook";
import { useContext } from "react";

function MobilePricing({ packs, priceValue, currency = "$" }) {
  const intl = useIntl();
  const { onCheckout } = usePricing();
  const langContext = useContext(LangContext);
  return (
    <Stack alignItems="center" bg="bgPrimary.500" width="100%">
      <Accordion defaultIndex={[0]} allowToggle width="100%">
        {packs?.map((pack) => (
          <AccordionItem key={pack.name} py={7}>
            <h2>
              <AccordionButton>
                <HStack
                  as="span"
                  flex="1"
                  textAlign="left"
                  justifyContent="space-between"
                >
                  <Heading as="h1" size="lg">
                    {pack.name}
                  </Heading>
                  <VStack>
                    <Heading as="h1" size="xl">
                      {currency}
                      {priceValue === "Month"
                        ? pack.price_month
                        : pack.price_month -
                          (pack.price_month * pack.percent_year) / 100}
                    </Heading>
                    {!(priceValue === "Month") && (
                      <Text
                        color="red.500"
                        textDecoration="line-through"
                        fontWeight="bold"
                      >
                        {currency}
                        {pack.price_month}
                      </Text>
                    )}
                  </VStack>
                </HStack>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <HStack justifyContent="end">
                <Button
                  colorScheme="brand"
                  color="black"
                  variant="solid"
                  size="md"
                  onClick={() => onCheckout(pack.lookup_key)}
                >
                  {intl.formatMessage({ id: `Pricing.select.button` })}
                </Button>
              </HStack>
              <VStack alignItems="start" mt={5}>
                {pack?.description?.map((desc) => (
                  <HStack key={desc._id}>
                    <CheckIcon fontSize="lg" color="green.500" pr={1} />
                    <Text>
                      {langContext.locale === "en" ? desc.en : desc.fr}
                    </Text>
                  </HStack>
                ))}
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Stack>
  );
}

export default MobilePricing;
