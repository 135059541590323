import { useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
} from '@mui/material';

// project import
import MainCard from '@BASE/components/MainCard';
import Transitions from '@BASE/components/@extended/Transitions';

// assets
import {
  BellOutlined,
  CloseOutlined,
  GiftOutlined,
  MessageOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem',
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none',
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Notification = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const { haveToUpgradeSoon, isExpired, isFirstConnection, differenceInDays } =
    useSelector((state) => state.auth);
  const intl = useIntl();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen = 'secondary.light';
  const iconBackColor = 'secondary.dark';
  const navigate = useNavigate();

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75, mr: '3%' }}>
      <IconButton
        disableRipple
        color="secondary"
        sx={{
          color: 'text.primary',
          bgcolor: open ? iconBackColorOpen : iconBackColor,
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge
          badgeContent={
            haveToUpgradeSoon || isExpired || isFirstConnection ? 1 : 0
          }
          color="primary"
        >
          <BellOutlined />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: '100%',
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 285,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="Notification"
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <IconButton size="small" onClick={handleToggle}>
                      <CloseOutlined />
                    </IconButton>
                  }
                >
                  <List
                    component="nav"
                    sx={{
                      p: 0,
                      '& .MuiListItemButton-root': {
                        py: 0.5,
                        '& .MuiAvatar-root': avatarSX,
                        '& .MuiListItemSecondaryAction-root': {
                          ...actionSX,
                          position: 'relative',
                        },
                      },
                    }}
                  >
                    {haveToUpgradeSoon && (
                      <ListItemButton
                        onClick={() => navigate('/dashboard?upgrade=true')}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              color: 'success.main',
                              bgcolor: 'success.lighter',
                            }}
                          >
                            <BellOutlined />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="h6">
                              <Typography component="span" variant="subtitle1">
                                {intl.formatMessage({
                                  id: 'dash.notification.upgrade',
                                })}
                                &nbsp;
                                {differenceInDays}&nbsp;
                                {intl.formatMessage({
                                  id: 'dash.notification.upgrade.day',
                                })}
                                {differenceInDays > 1 && 's'}, &nbsp;
                                {intl.formatMessage({
                                  id: 'dash.notification.upgrade.label',
                                })}
                                .
                              </Typography>{' '}
                            </Typography>
                          }
                          secondary={'AIBOOSTEZ'}
                        />
                      </ListItemButton>
                    )}

                    {isExpired && (
                      <ListItemButton
                        onClick={() => navigate('/dashboard?upgrade=true')}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              color: 'success.main',
                              bgcolor: 'success.lighter',
                            }}
                          >
                            <BellOutlined />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="h6">
                              <Typography component="span" variant="subtitle1">
                                {intl.formatMessage({
                                  id: 'dash.notification.expired',
                                })}
                              </Typography>{' '}
                            </Typography>
                          }
                          secondary={'AIBOOSTEZ'}
                        />
                      </ListItemButton>
                    )}

                    {isFirstConnection && (
                      <ListItemButton mb={10}>
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              color: 'success.main',
                              bgcolor: 'success.lighter',
                            }}
                          >
                            <BellOutlined />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle1">
                              {intl.formatMessage({
                                id: 'dash.notification.welcome',
                              })}
                            </Typography>
                          }
                          secondary={'AIBOOSTEZ'}
                        />
                      </ListItemButton>
                    )}

                    {!isExpired && !haveToUpgradeSoon && !isFirstConnection && (
                      <ListItemButton mb={10}>
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              color: 'success.main',
                              bgcolor: 'success.lighter',
                            }}
                          >
                            <CloseOutlined />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle1">
                              {intl.formatMessage({
                                id: 'dash.notification.noupgrade',
                              })}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    )}
                    <Divider />
                    <ListItemButton
                      sx={{ textAlign: 'center', py: `${12}px !important` }}
                      onClick={() => navigate('/dashboard/notifications')}
                    >
                      <ListItemText
                        primary={
                          <Typography variant="h6" color="primary">
                            View All
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Notification;
