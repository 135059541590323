// assets
import { ChromeOutlined, DashboardOutlined } from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined,
  ChromeOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const marketing  = (getTitle) => ({
  id: 'group-marketing-panel',
  code: 'MARKETING',
  title: getTitle("Dash.Menu.marketing"),
  type: 'collapse',
  icon: icons.ChromeOutlined,
  children: [
    {
      id: 'marketing-1',
      title: getTitle("Dash.Menu.marketing.customer_analysis"),
      type: 'item',
      url: '/dashboard/marketing/customer-analysis',
      icon: icons.DashboardOutlined,
      breadcrumbs: true
    },
    {
      id: 'marketing-2',
      title: getTitle("Dash.Menu.marketing.sales_webinars"),
      type: 'item',
      url: '/dashboard/marketing/sales-webinars',
      icon: icons.DashboardOutlined,
      breadcrumbs: true
    },
    {
      id: 'marketing-3',
      title: getTitle("Dash.Menu.marketing.strat_marketing"),
      type: 'item',
      url: '/dashboard/marketing/strat-marketing',
      icon: icons.DashboardOutlined,
      breadcrumbs: true
    },
    {
      id: 'marketing-4',
      title: getTitle("Dash.Menu.marketing.closing"),
      type: 'item',
      url: '/dashboard/marketing/closing',
      icon: icons.DashboardOutlined,
      breadcrumbs: true
    },
  ]
});

export default marketing;
