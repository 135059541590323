import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
// import '@fontsource/roboto/700.css';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { Provider as ReduxProvider } from 'react-redux';

import { StyledEngineProvider } from '@mui/material/styles';
// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

import App from './App.jsx';
import './styles/index.css';

import LangWrapper from '@BASE/store/contexts/LangWrapper';
import { store } from './store';

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <LangWrapper>
    <StyledEngineProvider injectFirst>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ReduxProvider>
    </StyledEngineProvider>
  </LangWrapper>

  // </React.StrictMode>
);
