import { createContext, useCallback, useContext, useState } from 'react';

const LoaderContext = createContext();

export const LoaderContextProvider = ({ children }) => {
  const [loader, setLoader] = useState(false);
  const [aiWork, setAiWork] = useState(false);
  const handleSetLoader = useCallback((value, isAiWork = false) => {
    setLoader(value);
    setAiWork(isAiWork);
  }, []);

  return (
    <LoaderContext.Provider
      value={{ loader, setLoader: handleSetLoader, aiWork }}
    >
      {children}
    </LoaderContext.Provider>
  );
};

export const useLoaderContext = () => useContext(LoaderContext);
