// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  position: null,
  reasons: [],
  contextFields:[],
  context: {},
  temporaryContext: {},
  assistantName: ''
};

// ==============================|| SLICE - ONBOARDING ||============================== //

const onboarding = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
   
    setContextFields(state, action) {
        state.contextFields = action.payload;
    },
    setContext(state, action) {
        state.context = action.payload;
    },
    setTemporaryContext(state, action) {
        state.temporaryContext = action.payload;
    },
    setPosition(state, action) {
        state.position = action.payload;
    },
    addReason(state, action) {
        state.reasons = [...(state.reasons) , action.payload];
    },
    removeReason(state, action) {
        state.reasons = (state.reasons).filter(item => item !== action.payload);
    },
    
    setAssistantName(state, action) {
        state.assistantName = action.payload;
    },
  }
});

export default onboarding.reducer;

export const {setPosition, addReason, removeReason,setAssistantName,
    setContext, setTemporaryContext, setContextFields
} = onboarding.actions;
