import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// import styledcomp  from 'styled-components';

import { Icon, Tab, Typography } from "@mui/material";

export const NavLink = ({ children, onClick }) => {
  return (
    <Typography
      component="a"
      onClick={onClick}
      color="text.secondary"
      sx={{
        fontSize: { xs: "100%", md: "88%", lg: "110%" },
        lineHeight: "32px",
        marginRight: "2rem",
        transition: "0.2s ease",
        "&:hover": {
          color: "primary.main",
          transform: "scale(1.1)",
          opacity: 1,
          cursor: "pointer",
        },
      }}
    >
      {children}
    </Typography>
  );
};

export const SectionTitle = ({ children, align, sx }) => {
  const color = useTheme().palette;
  const matchesSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const alignValue = align ? align : "left";
  return (
    <Typography
      component="h2"
      variant="h1"
      align={alignValue}
      sx={{
        fontWeight: 800,
        fontSize: { xs: "38px", md: "56px" },
        lineHeight: { xs: "48px", md: "56px" },
        textAlign: { xs: "center", md: "left" },
        mb: { xs: "12px", md: "16px" },
        // p: {xs: '40px 0 12px', md: '58px 0 16px'},
        ...(matchesSm && { mx: "auto" }),
        width: "fit-content",
        maxWidth: "fit-content",
        background: `linear-gradient(121.57deg, ${color.text.primary} 18.77%, ${color.text.secondary} 60.15%)`,
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const SectionSubtitle = ({ children, sx }) => {
  const color = useTheme().palette;
  // const alignValue = align ? align : 'left';
  return (
    <Typography
      component="span"
      sx={{
        fontWeight: 600,
        fontSize: { xs: "22px", md: "32px" },
        textAlign: { xs: "center", md: "left" },
        lineHeight: { xs: "32px", md: "48px" },
        mb: { xs: "10px", md: "14px" },
        // p: {xs: '40px 0 12px', md: '58px 0 16px'},
        marginInline: "0",
        marginBlock: "0",
        width: "fit-content",
        maxWidth: "fit-content",
        background: `linear-gradient(121.57deg, ${color.text.primary} 18.77%, ${color.text.secondary} 60.15%)`,
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const SectionText = ({ children, component, align, sx }) => {
  const color = useTheme().palette;
  return (
    <Typography
      component={component || "span"}
      align={align || "justify"}
      sx={{
        fontSize: { xs: "16px", md: "20px" },
        lineHeight: { xs: "30px", md: "38px" },
        // m: '0 auto',
        m: "30px 0",
        textAlign: "justify",
        pb: { xs: "24px", md: "3.6rem" },
        maxWidth: { xs: "600px", md: "700px" },
        width: "fit-content",
        color: color.text.primary,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export const SectionDivider = styled("div")(({ theme }) => ({
  width: "64px",
  height: "6px",
  borderRadius: "10px",
  background: `linear-gradient(270deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main}  100%)`,
  margin: "6rem 0",
}));

export const Button1 = styled("a")(({ theme }) => ({
  color: theme.palette.primary.main,
  border: "none",
  borderRadius: "10px",
  padding: "10px",
  transition: "0.4s ease",
  "&:hover": {
    transform: "scale(1.1)",
  },
}));

export const Button2 = styled("button")(({ theme }) => ({
  border: "none",
  color: theme.palette.text.primary,
  borderRadius: "50px",
  padding: "15px 20px",
  margin: "0 10px",
  fontSize: "22px",
  background: `linear-gradient(270deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main}  100%)`,
  fontWeight: "600",
  textTransform: "uppercase",
  transition: "0.4s ease",
  [theme.breakpoints.values.md]: {
    fontSize: "14px",
  },
  [theme.breakpoints.values.lg]: {
    fontSize: "20px",
  },
  "&:hover": {
    transform: "scale(1.1)",
    cursor: "pointer",
  },
}));

export const Img = styled("img")(() => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
}));

export const FooterIcon = ({ children, href, onClick }) => {
  // const theme = useTheme();
  return (
    <Icon
      component="a"
      href={href}
      onClick={onClick}
      target="_blank"
      color="action"
      // fontSize='large'
      sx={{
        fontSize: "30px",
        height: "fit-content",
        marginRight: "1rem",
        transition: "0.2s ease",
        "&:hover": {
          transform: "scale(1.2)",
          opacity: 1,
          color: "primary.main",
        },
      }}
    >
      {children}
    </Icon>
  );
};

export const CustomTab = styled(Tab)(({ theme }) => ({
  transition: "0.4s ease",
  "& .MuiTab-root .Mui-selected": {
    background: theme.palette.primary.main,
    fontSize: "50px",
  },
}));
