import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";

const DialogWrapper = ({ open, setOpen, maxWidth, children }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: { backgroundColor: "rgb(15, 22, 36)" },
          elevation: 0,
        }}
      >
        <DialogTitle sx={{ m: 0, py: 3 }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 12,
              top: 8,
              color: (theme) => theme.palette.text.primary,
            }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </>
  );
};

export default DialogWrapper;
