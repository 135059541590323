import { removeToken } from '@BASE/utils/token';
export function globalError(err, showToast, intl, navigate) {
  //To remove later
  if (err.message === '3times') {
    showToast({
      severity: 'error',
      message: intl.formatMessage({ id: 'Toast.message.error.context.limit' }),
    });
    return;
  }
  if (err.code === 'ERR_NETWORK') {
    showToast({
      severity: 'error',
      message: intl.formatMessage({ id: 'Toast.message.network' }),
    });
    if (err.response === undefined) {
      showToast({
        severity: 'error',
        message: intl.formatMessage({ id: 'Toast.message.error' }),
      });
    }
  } else if (err.response) {
    switch (err.response.status) {
      case 400:
        if (err.response.data.code === 'FIELD_DESERIALIZATION_ERROR')
          showToast({
            severity: 'info',
            message: intl.formatMessage({ id: 'Toast.message.wrongbody' }),
          });
        else {
          showToast({
            severity: 'error',
            message: intl.formatMessage({ id: 'Toast.message.error' }),
          });
          return false;
        }
        break;
      case 401:
        removeToken();
        localStorage.removeItem('user-infos');
        localStorage.removeItem('keep-me');
        navigate('/');
        if (err.response.data.code === 'TokenExpiredError') {
          showToast({
            severity: 'error',
            message: intl.formatMessage({ id: 'Toast.message.tokenexpired' }),
          });
        } else if (err.response.data.code === 'JsonWebTokenError') {
          showToast({
            severity: 'error',
            message: intl.formatMessage({ id: 'Toast.message.tokenexpired' }),
          });
        } else {
          showToast({
            severity: 'error',
            message: intl.formatMessage({ id: 'Toast.message.unauthorized' }),
          });
        }
        return false;
      // Handle 422 error
      case 422:
        showToast({
          severity: 'error',
          message: intl.formatMessage({ id: 'Toast.message.422' }),
        });
        return false;
      case 403:
        if (err.response.data.code === 'PROMPT_API_TOKEN_NOT_VALID')
          showToast({
            severity: 'error',
            message: intl.formatMessage({ id: 'Toast.message.prompttoken' }),
          });
        else if (err.response.data.code === 'NO_PLAN')
          showToast({
            severity: 'error',
            message: intl.formatMessage({ id: 'Toast.message.plan_expired' }),
          });
        else if (err.response.data.code === 'PLAN_INACTIVE')
          showToast({
            severity: 'error',
            message: intl.formatMessage({ id: 'Toast.message.plan_inactive' }),
          });
        else if (err.response.data.code === 'ACTION_NOT_AUTHORIZED')
          showToast({
            severity: 'warning',
            message: intl.formatMessage({
              id: 'Toast.message.notauthorized_upgrade',
            }),
          });
        else {
          showToast({
            severity: 'error',
            message: intl.formatMessage({ id: 'Toast.message.error' }),
          });
          return false;
        }
        break;
      case 404:
        if (
          err.response.data.code === 'USER_FOUNDING_BY_EMAIL_ERROR' ||
          err.response.data.code === 'USER_NOT_FOUND_ERROR' ||
          err.response.data.code === 'USER_NOT_FOUND_FOR_FORGOT_PASSSWORD'
        )
          showToast({
            severity: 'error',
            message: intl.formatMessage({ id: 'Toast.message.usernotfound' }),
          });
        else {
          showToast({
            severity: 'error',
            message: intl.formatMessage({ id: 'Toast.message.notfound' }),
          });
          return false;
        }
        if (err.response.data.code === 'USER_NOT_FOUND_ERROR') return false;
        break;
      case 500:
        showToast({
          severity: 'error',
          message: intl.formatMessage({ id: 'Toast.message.internalerror' }),
        });
        break;
      case 502:
        showToast({
          severity: 'error',
          message: intl.formatMessage({ id: 'Toast.message.prompterror' }),
        });
        break;
      case 412:
        showToast({
          severity: 'error',
          message: intl.formatMessage({ id: 'Toast.message.unauthorized.412' }),
        });
        navigate('/dashboard?upgrade=true');
        break;
      default:
        showToast({
          severity: 'error',
          message: intl.formatMessage({ id: 'Toast.message.error' }),
        });
        return false;
    }
  } else {
    showToast({
      severity: 'error',
      message: intl.formatMessage({ id: 'Toast.message.error' }),
    });
    return false;
  }
  return true;
}
