import {
  SectionText,
  SectionTitle,
} from "@BASE/components/LandingPage/GlobalComponents";
import { useUpgradeContext } from "@BASE/store/contexts/openUpgrade";
import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import Pricing from "../Pricing/Pricing";

const PricingContainer = () => {
  const { upgradeMessage, setUpgradeMessage } = useUpgradeContext();
  const [message, setMessage] = useState("");

  useEffect(() => {
    setMessage(upgradeMessage);
    setUpgradeMessage("");
  }, [setMessage, setUpgradeMessage, upgradeMessage]);

  return (
    <Box h="100%">
      <SectionTitle>
        <FormattedMessage id="Dash.Menu.upgrade" />
      </SectionTitle>
      {message && <SectionText align="center">{message}</SectionText>}
      <Pricing />
    </Box>
  );
};

export default PricingContainer;
