import { isTokenExpired } from '@BASE/utils/token';
import { getToken } from '@BASE/utils/token';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { createContext, useContext, useState } from 'react';
import { useToastContext } from '@BASE/store/contexts';
import { useLoaderContext } from '@BASE/store/contexts/loader';
import { globalError } from '@BASE/utils/actions/errors';
import { client } from '@BASE/utils/client';

const storageKey = import.meta.env.VITE_ACCESS_TOKEN_STORAGE_KEY;
const PricingContext = createContext();

function PrincingProvider({ children }) {
  const [duration, setDuration] = useState('');
  const [message, setMessage] = useState('');

  const navigate = useNavigate();
  const intl = useIntl();
  const { showToast } = useToastContext();
  const { setLoader } = useLoaderContext();

  const sabonner = async (lookup_key) => {
    let timer = '';
    switch (duration) {
      case 'Year':
      case 'Année':
        timer = 'YEARLY';
        break;
      case 'Month':
      case 'Mois':
        timer = 'MONTHLY';
        break;
    }
    try {
      setLoader(true);
      let response = await client.post(
        `/pay/checkout/${lookup_key}/${timer.toUpperCase()}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${getToken(
              localStorage.getItem(storageKey)
            )}`,
          },
        }
      );
      window.location.href = response.data.session.url;
    } catch (err) {
      console.log(err);
      globalError(err, showToast, intl);
    } finally {
      setLoader(false);
    }
  };

  async function onCheckout(lookupkey) {
    if (isTokenExpired()) {
      navigate('/');
    } else {
      sabonner(lookupkey);
    }
  }
  return (
    <PricingContext.Provider
      value={{
        duration,
        message,
        setMessage,
        setDuration,
        onCheckout,
      }}
    >
      {children}
    </PricingContext.Provider>
  );
}

function usePricing() {
  const context = useContext(PricingContext);
  if (context === undefined)
    throw new Error('Pricing context is used out of context');
  return context;
}

export { PrincingProvider, usePricing };
