import { extendTheme } from "@chakra-ui/react";

const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

export const theme = extendTheme({
  styles: {
    global: () => ({
      body: {
        bg: "#0f1624",
        color: "#FFFFFF",
      },
    }),
  },
  colors: {
    brand: {
      100: "#dbfacf",
      200: "#b7f49e",
      300: "#93ef6e",
      400: "#6fe93d",
      500: "#4be40d",
      600: "#3cb60a",
      700: "#2d8908",
      800: "#1e5b05",
      900: "#0f2e03",
    },
    bgPrimary: {
      100: "#cfd0d3",
      200: "#9fa2a7",
      300: "#6f737c",
      400: "#3f4550",
      500: "#0f1624",
      600: "#0c121d",
      700: "#090d16",
      800: "#06090e",
      900: "#030407",
    },
  },
  config,
});
