import { useToastContext } from '@BASE/store/contexts';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

const ReferralLinkCard = ({ referralId }) => {
  const { showToast } = useToastContext();
  const intl = useIntl();

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(referralId);
      showToast({
        severity: 'success',
        message: intl.formatMessage({ id: 'dash.referral.stats.copy' }),
      });
    } catch (err) {
      showToast({
        severity: 'error',
        message: 'Copy failed',
      });
    }
  };

  return (
    <Box
      p={2}
      sx={{
        width: 2 / 4,
        minWidth: '300px',
        maxWidth: '600px',
        border: 1,
        borderColor: 'primary.main',
        borderRadius: '12px',
        boxShadow: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3,
        height: '200px',
      }}
    >
      <Box width={'100%'}>
        <Typography variant="h4" mb={2}>
          {intl.formatMessage({ id: 'dash.referral.stats.link.text' })}
        </Typography>
        <Box
          justifyContent="center"
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <TextField
            id="outlined-basic"
            variant="outlined"
            defaultValue={referralId}
            sx={{
              // change label and border color when readonly
              '&:has([readonly]) ': {
                '& .MuiInputLabel-outlined': {
                  color: 'primary',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'primary ',
                },
              },
              width: 2 / 3,
              minWidth: '100%',
              backgroundColor: '#1C263A',
            }}
            inputProps={{ readOnly: true }}
          />
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<ContentCopyIcon />}
            sx={{
              py: 1,
              fontWeight: 'bold',
              borderRadius: '35px',
            }}
            onClick={handleCopyToClipboard}
            disabled={referralId === ''}
          >
            {intl.formatMessage({ id: 'dash.referral.stats.copy.text' })}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ReferralLinkCard;
