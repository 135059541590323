// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  contextFields: [],
  extensionContextFields: [],
  contexts: [],
  extensionContexts: [],
  extensionContext: {},
  context: {},
  assistantName: '',
  isConnectedToLinkedIn: false,
  reloadContexts: 0,
};

// ==============================|| SLICE - PROMPT CONTEXT ||============================== //

const contexts = createSlice({
  name: 'contexts',
  initialState,
  reducers: {
    setContextFields(state, action) {
      state.contextFields = action.payload;
    },
    setExtensionContextFields(state, action) {
      state.extensionContextFields = action.payload;
    },
    setContexts(state, action) {
      state.contexts = action.payload;
    },
    setCurrentContext(state, action) {
      state.context = action.payload;
    },
    setExtensionContexts(state, action) {
      state.extensionContexts = action.payload;
    },
    setCurrentExtensionContext(state, action) {
      state.extensionContext = action.payload;
    },
    setAssistantName(state, action) {
      state.assistantName = action.payload;
    },
    setReloadContexts(state, action) {
      state.reloadContexts = state.reloadContexts + 1;
    },
    setIsConnectedToLinkedIn(state, action) {
      state.isConnectedToLinkedIn = action.payload;
    },
  },
});

export default contexts.reducer;

export const {
  setContextFields,
  setContexts,
  setCurrentContext,
  setAssistantName,
  setReloadContexts,
  setIsConnectedToLinkedIn,
  setExtensionContexts,
  setCurrentExtensionContext,
  setExtensionContextFields,
} = contexts.actions;
