// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  accessToken: null,
  username: null,
  isUserConnected: false,
  haveToUpgradeSoon: false,
  isFirstConnection: false,
  differenceInDays: 0,
  isExpired: false,
  sections: [],
  authorizationData: null,
};

// ==============================|| SLICE - MENU ||============================== //

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserIsConnected(state, action) {
      state.isUserConnected = true;
    },

    setUserIsDisconnected(state, action) {
      state.isUserConnected = false;
    },

    setAccessToken(state, action) {
      state.accessToken = action.payload;
    },

    setUsername(state, action) {
      state.username = action.payload;
    },

    setSections(state, action) {
      state.sections = action.payload;
    },

    setHaveToUpgradeSoon(state, action) {
      state.haveToUpgradeSoon = action.payload;
    },

    setIsExpired(state, action) {
      state.isExpired = action.payload;
    },

    setIsFirstConnection(state, action) {
      state.isFirstConnection = action.payload;
    },
    setDifferenceInDays(state, action) {
      state.differenceInDays = action.payload;
    },
    setAuthorizationData(state, action) {
      state.authorizationData = action.payload;
    },
  },
});

export default auth.reducer;

export const {
  setUserIsConnected,
  setUserIsDisconnected,
  setAccessToken,
  setUsername,
  setSections,
  setHaveToUpgradeSoon,
  setIsExpired,
  setIsFirstConnection,
  setDifferenceInDays,
  setAuthorizationData,
} = auth.actions;
