import { Box, Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { client } from '@BASE/utils/client';
import { getToken } from '@BASE/utils/token';
import HeaderContent from '../../layout/Header/HeaderContent';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga4';

const storageKey = import.meta.env.VITE_ACCESS_TOKEN_STORAGE_KEY;

function Subscription() {
  const [userPlan, setUserPlan] = useState();
  const { isExpired } = useSelector((state) => state.auth);
  const intl = useIntl();
  useEffect(() => {
    const getAuthorisation = async () => {
      let response = await client.get('/users/get-authorization');
      setUserPlan(response?.data?.data);
    };
    getAuthorisation();
  }, []);
  return (
    <>
      {userPlan?.userPlan?._plan_id ? (
        <Box
          display="flex"
          mx={'auto'}
          flexDirection="column"
          height="85vh"
          width="70%"
          justifyContent="space-between"
          alignItems={''}
        >
          <Box m={'auto'} width={'100%'}>
            <Box
              display="flex"
              justifyContent={'space-between'}
              alignItems={'center'}
              mb={1}
            >
              <Box width={'50%'}>
                <Typography variant="h4">
                  {intl.formatMessage({ id: `Billing.current.pack.title` })}
                </Typography>
              </Box>
              <HeaderContent show={false} />
            </Box>
            <Divider />
            <Box mt={4} mb={4}>
              <Typography variant="h3" mb={2}>
                {userPlan?.userPlan?._plan_id?.name}
              </Typography>
              <Typography
                display={'inline'}
                color={'primary'}
                fontWeight="bold"
                variant="h4"
              >
                {userPlan?.userPlan?._plan_id?.price_month}$
              </Typography>{' '}
              <Typography
                variant="h4"
                fontWeight="bold"
                mb={2}
                display={'inline'}
              >
                {intl.formatMessage({ id: `Pricing.duration.text` })}
              </Typography>
              {userPlan?.userPlan?._renewal_date ? (
                <Box mt={2}>
                  <Typography variant="body1" mb={2} display={'inline'}>
                    {intl.formatMessage({ id: `Billing.date.message` })}
                  </Typography>
                  <Typography
                    display={'inline'}
                    color={'primary'}
                    fontWeight="bold"
                    variant="h4"
                  >
                    {new Date(
                      userPlan?.userPlan?._renewal_date
                    ).toLocaleDateString()}
                    .
                  </Typography>{' '}
                </Box>
              ) : (
                <Typography variant="body1" mb={2}>
                  {intl.formatMessage({ id: `Billing.date.message.expired` })}
                </Typography>
              )}
            </Box>
            <Box>
              <Typography variant="h4" mb={1}>
                {intl.formatMessage({ id: `Billing.action.detail.title` })}
              </Typography>
              <Divider />
            </Box>
            <Box mt={5}>
              <Typography variant="body1" mt={2} display={'inline'}>
                {intl.formatMessage({ id: `Billing.social.title` })}: &nbsp;
              </Typography>
              <Typography
                display={'inline'}
                color={'primary'}
                fontWeight="bold"
                variant="h4"
              >
                {userPlan?._usage_count?._current_count}
              </Typography>{' '}
              <Typography display={'inline'} fontWeight="bold" variant="h4">
                / {userPlan?._usage_count?._max_count}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" mt={2} display={'inline'}>
                {intl.formatMessage({ id: `Billing.extension.title` })}: &nbsp;
              </Typography>
              <Typography
                display={'inline'}
                color={'primary'}
                fontWeight="bold"
                variant="h4"
              >
                {userPlan?._extension_action_usage?._current_count}
              </Typography>
              <Typography display={'inline'} fontWeight="bold" variant="h4">
                / {userPlan?._extension_action_usage?._max_count}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" mb={2} mt={2} display={'inline'}>
                {intl.formatMessage({ id: `Billing.dashboard.title` })}:&nbsp;
              </Typography>
              <Typography
                display={'inline'}
                color={'primary'}
                fontWeight="bold"
                variant="h4"
              >
                {userPlan?._dashboard_usage?._current_count}
              </Typography>
              <Typography display={'inline'} fontWeight="bold" variant="h4">
                / {userPlan?._dashboard_usage?._max_count}
              </Typography>
            </Box>
          </Box>
          <Box textAlign={'center'}>
            <Typography variant="body2" display={'inline'}>
              {intl.formatMessage({ id: `Billing.click.title` })} &nbsp;
            </Typography>
            <Typography
              display={'inline'}
              color={'primary'}
              variant="body2"
              sx={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={async () => {
                ReactGA.event({
                  category: 'Dashboard client button',
                  action: 'Click',
                  label: 'unsubscribe button',
                });
                const response = await client.post(
                  '/pay/portal',
                  {},
                  {
                    headers: {
                      Authorization: `Bearer ${getToken(
                        localStorage.getItem(storageKey)
                      )}`,
                    },
                  }
                );
                window.open(response?.data?.session?.url, '_blank');
              }}
            >
              {intl.formatMessage({ id: `Billing.here.title` })}
            </Typography>{' '}
            <Typography variant="body2" display={'inline'}>
              {intl.formatMessage({ id: `Billing.cancel.title` })}
            </Typography>
          </Box>
        </Box>
      ) : isExpired ? (
        <Box
          display="flex"
          mx={'auto'}
          flexDirection={'column'}
          height="85vh"
          width="60%"
          justifyContent="center"
          alignItems={'center'}
        >
          <Typography variant="h3" mb={2} textAlign={'center'}>
            {intl.formatMessage({ id: `dash.notification.expired` })}
          </Typography>
          <HeaderContent show={false} />
        </Box>
      ) : (
        <Box
          display="flex"
          mx={'auto'}
          flexDirection={'column'}
          height="85vh"
          width="60%"
          justifyContent="center"
          alignItems={'center'}
        >
          <Typography variant="h3" mb={2} textAlign={'center'}>
            {intl.formatMessage({ id: `Billing.unsuscribe.title` })}
          </Typography>
          <Typography variant="h4" mb={2} textAlign={'center'}>
            {intl.formatMessage({ id: `Billing.unsuscribe.subtitle` })}
          </Typography>
          <Box mb={2}>
            <Box>
              <Typography variant="body1" mt={2} display={'inline'}>
                {intl.formatMessage({ id: `Billing.social.title` })}: &nbsp;
              </Typography>
              <Typography
                display={'inline'}
                color={'primary'}
                fontWeight="bold"
                variant="h4"
              >
                {userPlan?._usage_count?._current_count}
              </Typography>{' '}
              <Typography display={'inline'} fontWeight="bold" variant="h4">
                / {userPlan?._usage_count?._max_count}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" mt={2} display={'inline'}>
                {intl.formatMessage({ id: `Billing.extension.title` })}: &nbsp;
              </Typography>
              <Typography
                display={'inline'}
                color={'primary'}
                fontWeight="bold"
                variant="h4"
              >
                {userPlan?._extension_action_usage?._current_count}
              </Typography>
              <Typography display={'inline'} fontWeight="bold" variant="h4">
                / {userPlan?._extension_action_usage?._max_count}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" mb={2} mt={2} display={'inline'}>
                {intl.formatMessage({ id: `Billing.dashboard.title` })}:&nbsp;
              </Typography>
              <Typography
                display={'inline'}
                color={'primary'}
                fontWeight="bold"
                variant="h4"
              >
                {userPlan?._dashboard_usage?._current_count}
              </Typography>
              <Typography display={'inline'} fontWeight="bold" variant="h4">
                / {userPlan?._dashboard_usage?._max_count}
              </Typography>
            </Box>
          </Box>
          <HeaderContent show={false} />
        </Box>
      )}
    </>
  );
}

export default Subscription;
