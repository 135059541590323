import React, { useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
  DialogActions,
  Button,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Dialog,
} from '@mui/material';

// project import
import MainCard from '@BASE/components/MainCard';
import Transitions from '@BASE/components/@extended/Transitions';

// assets
import {
  BellOutlined,
  CloseOutlined,
  GiftOutlined,
  MessageOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem',
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none',
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const NotificationPage = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { haveToUpgradeSoon, isExpired, isFirstConnection } = useSelector(
    (state) => state.auth
  );
  const intl = useIntl();

  const handleClickOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  return (
    <Box sx={{ flexShrink: 0, mx: 'auto', width: '80%' }}>
      <Paper
        sx={{
          boxShadow: theme.customShadows.z1,
          width: '100%',
          minWidth: 285,
          maxWidth: 420,
          [theme.breakpoints.down('md')]: {
            maxWidth: 285,
          },
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <MainCard
            title="Notification"
            elevation={0}
            border={false}
            content={false}
          >
            <List
              component="nav"
              sx={{
                p: 0,
                '& .MuiListItemButton-root': {
                  py: 0.5,
                  '& .MuiAvatar-root': avatarSX,
                  '& .MuiListItemSecondaryAction-root': {
                    ...actionSX,
                    position: 'relative',
                  },
                },
              }}
            >
              {haveToUpgradeSoon && (
                <ListItemButton onClick={handleClickOpenDrawer}>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        color: 'success.main',
                        bgcolor: 'success.lighter',
                      }}
                    >
                      <BellOutlined />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="h6">
                        <Typography component="span" variant="subtitle1">
                          {intl.formatMessage({
                            id: 'dash.notification.upgrade',
                          })}
                          secondary={'AIBOOSTEZ'}
                        </Typography>{' '}
                      </Typography>
                    }
                  />
                </ListItemButton>
              )}

              {isExpired && (
                <ListItemButton onClick={handleClickOpenDrawer}>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        color: 'success.main',
                        bgcolor: 'success.lighter',
                      }}
                    >
                      <BellOutlined />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="h6">
                        <Typography component="span" variant="subtitle1">
                          {intl.formatMessage({
                            id: 'dash.notification.expired',
                          })}
                        </Typography>{' '}
                      </Typography>
                    }
                    secondary={'AIBOOSTEZ'}
                  />
                </ListItemButton>
              )}

              {isFirstConnection && (
                <ListItemButton mb={10} onClick={handleClickOpenDrawer}>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        color: 'success.main',
                        bgcolor: 'success.lighter',
                      }}
                    >
                      <BellOutlined />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1">
                        {intl.formatMessage({
                          id: 'dash.notification.welcome',
                        })}
                      </Typography>
                    }
                    secondary={'AIBOOSTEZ'}
                  />
                </ListItemButton>
              )}

              {!isExpired && !haveToUpgradeSoon && !isFirstConnection && (
                <ListItemButton mb={10}>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        color: 'success.main',
                        bgcolor: 'success.lighter',
                      }}
                    >
                      <CloseOutlined />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1">
                        {intl.formatMessage({
                          id: 'dash.notification.noupgrade',
                        })}
                      </Typography>
                    }
                  />
                </ListItemButton>
              )}
              <ListItemButton onClick={handleClickOpenDrawer}>
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      color: 'success.main',
                      bgcolor: 'success.lighter',
                    }}
                  >
                    <GiftOutlined />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="h6">
                      It&apos;s{' '}
                      <Typography component="span" variant="subtitle1">
                        Cristina danny&apos;s
                      </Typography>{' '}
                      birthday today.
                    </Typography>
                  }
                  secondary="2 min ago"
                />
                <ListItemSecondaryAction>
                  <Typography variant="caption" noWrap>
                    3:00 AM
                  </Typography>
                </ListItemSecondaryAction>
              </ListItemButton>
              <ListItemButton onClick={handleClickOpenDrawer}>
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      color: 'success.main',
                      bgcolor: 'success.lighter',
                    }}
                  >
                    <GiftOutlined />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="h6">
                      It&apos;s{' '}
                      <Typography component="span" variant="subtitle1">
                        Cristina danny&apos;s
                      </Typography>{' '}
                      birthday today.
                    </Typography>
                  }
                  secondary="2 min ago"
                />
                <ListItemSecondaryAction>
                  <Typography variant="caption" noWrap>
                    3:00 AM
                  </Typography>
                </ListItemSecondaryAction>
              </ListItemButton>
            </List>
          </MainCard>
        </ClickAwayListener>
      </Paper>

      <React.Fragment>
        <Dialog
          // fullScreen={fullScreen}
          open={openDrawer}
          onClose={handleCloseDrawer}
          aria-labelledby="responsive-dialog-title"
          width="70%"
        >
          <DialogTitle id="responsive-dialog-title">
            {'Notification page'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Repudiandae dolorum, vitae adipisci enim magni veniam quod ex
              optio quasi eos laborum, voluptas iusto atque? Quasi illum maxime
              eaque praesentium soluta.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCloseDrawer}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </Box>
  );
};

export default NotificationPage;
