import { Box, Typography } from '@mui/material';

const ReferralPersonCountCard = ({
  title,
  affiliateNumber,
  percentSymbol = false,
}) => {
  return (
    <Box
      p={2}
      sx={{
        width: 2 / 4,
        minWidth: '300px',
        maxWidth: '500px',
        border: 1,
        borderColor: 'primary.main',
        borderRadius: '12px',
        boxShadow: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        height: '200px',
      }}
    >
      <Typography variant="h4" textAlign={'center'} gutterBottom>
        {title}
      </Typography>
      <Typography
        variant="h1"
        gutterBottom
        color={'primary.main'}
        fontWeight={'bold'}
        sx={{
          verticalAlign: 'middle',
          textAlign: 'center',
        }}
      >
        {Intl.NumberFormat('en-US').format(affiliateNumber)}
        {percentSymbol && '%'}
      </Typography>
    </Box>
  );
};

export default ReferralPersonCountCard;
