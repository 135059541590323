import { useIntl } from 'react-intl';

export const useData = () => {
  const intl = useIntl();
  return {
    pricingTimeDurations: [
      {
        name: intl.formatMessage({ id: `Pricing.month.text` }),
        value: 'month',
      },
      {
        name: intl.formatMessage({ id: `Pricing.year.text` }),
        value: 'year',
      },
    ],
  };
};
