import axios from "axios";
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";

// var myFingerPrint = await getCurrentBrowserFingerPrint()

// console.log('myFingerPrint', myFingerPrint);

export const client = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_BASE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
  // withCredentials: true,
});

export const clientF = async () =>
  axios.create({
    baseURL: import.meta.env.VITE_BACKEND_BASE_URL,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "x-my-user-agent": await getCurrentBrowserFingerPrint(),
    },
    // withCredentials: true,
  });


export const linkedinClient = axios.create({
    baseURL: 'https://api.linkedin.com/v2',
    headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    },
});

export const imagekitClient = axios.create({
  baseURL: 'https://upload.imagekit.io/api/v1',
  headers: {
    'Authorization': 'Basic cHJpdmF0ZV81bXhBQlFvT2dzZkFsNGdvNXpTSFI5MEZJaEU9Og==',
  },
});

export const axiosInstanceForLinkedIn = axios.create({
  baseURL: 'http://localhost:5173',
  headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
});

export const setUserAgent = async (client) => {
  client.interceptors.request.use(
    async (config) => {
      config.headers["x-my-user-agent"] = await getCurrentBrowserFingerPrint();
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  // console.log("client", client);
};

export const gptAPIClient = axios.create({
  baseURL: "https://api.openai.com/v1",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    Authorization: `Bearer ${import.meta.env.VITE_REACT_APP_OPENAI_API_KEY}`,
  },
});
