// assets
import { ChromeOutlined, DashboardOutlined } from '@ant-design/icons';
import EditNoteIcon from '@mui/icons-material/EditNote';

// icons
const icons = {
  DashboardOutlined,
  ChromeOutlined,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const contents = (getTitle) => ({
  id: 'group-contents-panel',
  code: 'CONTENUS',
  title: getTitle('Dash.Menu.contents'),
  type: 'collapse',
  icon: EditNoteIcon,
  children: [
    {
      id: 'contents-1',
      title: getTitle('Dash.Menu.contents.ghostwriter'),
      type: 'item',
      url: '/dashboard/contents/ghostwriter',
      icon: icons.DashboardOutlined,
      breadcrumbs: true,
    },
    {
      id: 'contents-2',
      title: getTitle('Dash.Menu.contents.community_manager'),
      type: 'item',
      url: '/dashboard/contents/community-manager',
      icon: icons.DashboardOutlined,
      breadcrumbs: true,
    },
    {
      id: 'contents-3',
      title: getTitle('Dash.Menu.contents.content_ideas'),
      type: 'item',
      url: '/dashboard/contents/content-ideas',
      icon: icons.DashboardOutlined,
      breadcrumbs: true,
    },
    {
      id: 'contents-4',
      title: getTitle('Dash.Menu.contents.saved_contents'),
      type: 'item',
      url: '/dashboard/contents/saved-contents',
      icon: icons.DashboardOutlined,
      breadcrumbs: true,
    },
    {
      id: 'contents-5',
      title: getTitle('Dash.Menu.contents.seo'),
      type: 'item',
      url: '/dashboard/contents/seo',
      icon: icons.DashboardOutlined,
      breadcrumbs: true,
    },
  ],
});

export default contents;
