import { useContext, useRef, useState } from "react";

import CheckIcon from "@mui/icons-material/Check";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import {
  Button,
  ClickAwayListener,
  Grow,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from "@mui/material";

import { LangContext } from "@BASE/store/contexts/LangWrapper";
import { useLoaderContext } from "@BASE/store/contexts/loader";

const LangButton = () => {
  const langContext = useContext(LangContext);
  const { setLoader } = useLoaderContext();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const languages = [
    { code: "fr", countryIsoCode: "fr", name: "Français" },
    { code: "en", countryIsoCode: "gb", name: "English" },
  ];

  const currentCountryCode = languages.find(
    (l) => l.code === langContext.locale
  ).countryIsoCode;

  const handleToggleMenu = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleSelectLanguage = (code) => {
    setOpen(false);
    setLoader(true);
    setTimeout(() => {
      langContext.selectLanguage(code);
      setLoader(false);
    }, 500);
  };

  return (
    <>
      <Button
        variant="text"
        sx={{ p: 0 }}
        ref={anchorRef}
        onClick={handleToggleMenu}
        aria-label="change language"
        aria-controls="menu-lang"
        aria-haspopup="true"
        endIcon={
          open ? (
            <ExpandLessOutlinedIcon sx={{ m: 0, p: 0 }} />
          ) : (
            <ExpandMoreOutlinedIcon sx={{ m: 0, p: 0 }} />
          )
        }
      >
        <LangFlag countryIsoCode={currentCountryCode} />
      </Button>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  // onKeyDown={handleListKeyDown}
                >
                  {languages.map((lang) => (
                    <MenuItem
                      key={lang.code}
                      onClick={() => handleSelectLanguage(lang.code)}
                    >
                      <Tooltip title={lang.name} placement="right">
                        <>
                          <LangFlag countryIsoCode={lang.countryIsoCode} />
                          <Typography variant="h6" paddingX="10%">
                            {lang.name}
                          </Typography>
                        </>
                      </Tooltip>
                      {langContext.locale === lang.code && (
                        <ListItemIcon>
                          <CheckIcon color="primary" />
                        </ListItemIcon>
                      )}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default LangButton;

const LangFlag = ({ countryIsoCode }) => {
  return (
    <img
      width="16"
      height="12"
      alt={countryIsoCode}
      src={`https://flagcdn.com/16x12/${countryIsoCode}.png`}
      srcSet={`https://flagcdn.com/32x24/${countryIsoCode}.png 2x, 
					https://flagcdn.com/48x36/${countryIsoCode}.png 3x`}
    />
  );
};
export { LangFlag };
