// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  schedules: [],
  schedule: {},
  reloadSchedules: 0,
  contentText: ''
};

// ==============================|| SLICE - PROMPT CONTEXT ||============================== //

const scheduling = createSlice({
  name: "scheduling",
  initialState,
  reducers: {
    setSchedules(state, action) {
      state.schedules = action.payload;
    },
    setSchedule(state, action) {
      state.schedule = action.payload;
    },
    setReloadSchedules(state, action) {
      state.reloadSchedules = state.reloadSchedules + 1;
    },
    setContentText(state, action) {
      state.contentText = action.payload;
    },
  },
});

export default scheduling.reducer;

export const { setSchedules, setSchedule, setReloadSchedules, setContentText } =
  scheduling.actions;
