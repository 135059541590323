// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  email: null,
  password: null,
  otp: null,
  renderOptForm: false,
  renderForgetPasswordOtp: false,
  renderForgetPasswordForm: false,
  firstName: null,
  lastName: null,
};

// ==============================|| SLICE - MENU ||============================== //

const register = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveEmail(state, action) {
        state.email = action.payload;
    },
    savePassword(state, action) {
      state.password = action.payload;
    },
    saveOtp(state, action) {
      state.otp = action.payload;
    },

    renderOptForm(state, action) {
        state.renderOptForm = true;
    },
    notRenderOtpForm(state, action) {
        state.renderOptForm = false;
    },

    renderForgetPasswordOtp(state, action) {
      state.renderOptForm = true;
    },
    notRenderForgetPasswordOtp(state, action) {
        state.renderOptForm = false;
    },
    renderForgetPasswordForm(state, action) {
      state.renderOptForm = true;
    },
    notRenderForgetPasswordForm(state, action) {
        state.renderOptForm = false;
    },

    saveFirstName(state, action) {
      state.firstName = action.payload;
    },
    saveLastName(state, action) {
      state.lastName = action.payload;
    },
    saveFirstAndLastName(state, action) {
      state.firstName = action.payload[0];
      state.lastName = action.payload[1];
    },
    deleteSignupData(state, action){
      state.firstName = '';
      state.lastName ='';
      state.password='';
      state.email='';
    }

  }
});

export default register.reducer;

export const { saveEmail, savePassword, saveOtp, renderOptForm, notRenderOtpForm, 
  renderForgetPasswordOtp, notRenderForgetPasswordOtp, 
  renderForgetPasswordForm, notRenderForgetPasswordForm,
  firstName, lastName, saveFirstName, saveLastName, saveFirstAndLastName, deleteSignupData
 } = register.actions;
