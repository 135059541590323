import { FormattedMessage } from 'react-intl';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { useLoaderContext } from '@BASE/store/contexts/loader';
import { Typography } from '@mui/material';

export default function LoaderOverlay({ noMessage }) {
  const { loader, aiWork } = useLoaderContext();
  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: 2000 }}
        open={loader}
        // onClick={handleClose}
      >
        <CircularProgress color="primary" />
        {!noMessage && (
          <Typography variant="subtitle1" color="primary.main" sx={{ ml: 2 }}>
            <FormattedMessage
              id={aiWork ? 'Loading.OverLay.text.ai' : 'Loading.OverLay.text'}
            />
          </Typography>
        )}
      </Backdrop>
    </div>
  );
}
