import { useToastContext } from "@BASE/store/contexts";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const ToastNotification = () => {
  const { toastState, setToastState } = useToastContext();

  const { vertical, horizontal, message, open, severity, duration } =
    toastState;

  // useEffect(() => {
  //   setToastState({ ...toastState, open: true });
  // }, [toastState])

  const handleClose = () => {
    // if (reason === 'clickaway') {
    //     return;
    // }
    setToastState((prev) => ({ ...prev, open: false }));
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleClose}
      autoHideDuration={duration}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{ width: "100%" }}
        variant="filled"
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ToastNotification;
