// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  contentIdeas:[],
  contentIdea: {},
  savedContents: [],
  savedContent: {},
	reloadContents: 0,
  reloadSavedContents: 0,
};

// ==============================|| SLICE - PROMPT CONTEXT ||============================== //

const contexts = createSlice({
  name: 'contents',
  initialState,
  reducers: {
    setContentIdeas(state, action) {
      state.contentIdeas = action.payload;
    },
    setContentIdea(state, action) {
      state.contentIdea = action.payload;
    },
    setSavedContents(state, action) {
      state.savedContents = action.payload;
    },
    setSavedContent(state, action) {
      state.savedContent = action.payload;
    },
    setReloadContents(state, action) {
      state.reloadContents = state.reloadContents + 1;
    },
    setReloadSavedContents(state, action) {
      state.reloadSavedContents = state.reloadSavedContents + 1;
    }
  }
});

export default contexts.reducer;

export const {setContentIdeas, setContentIdea, setSavedContents, 
  setSavedContent, setReloadContents, setReloadSavedContents} = contexts.actions;
