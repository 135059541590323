import React, {useState} from 'react';
import {IntlProvider} from 'react-intl';
import French from '@BASE/data/lang/fr';
import English from '@BASE/data/lang/en';

export const LangContext = React.createContext();

const navLocal = navigator.language;
let local;

let lang;
if (navLocal === 'en' || navLocal === 'en-US' || navLocal === 'en-UK' 
    || navLocal === 'en-CA' || navLocal === 'en-AU'
) {
    lang = English;
    local = 'en';
} else if (navLocal === 'fr' || navLocal === 'fr-FR' || navLocal === 'fr-CA' || navLocal === 'fr-BE'
    || navLocal === 'fr-CH' || navLocal === 'fr-LU ' || navLocal === 'fr-MC' || navLocal === 'fr-WF'
    || navLocal === 'fr-MF' || navLocal === 'fr-RE' || navLocal === 'fr-YT' || navLocal === 'fr-BL'
    || navLocal === 'fr-GF' || navLocal === 'fr-PF' || navLocal === 'fr-TF' || navLocal === 'fr-NC '
) {
    lang = French;
    local = 'fr';
} else {
    lang = French;
    local = 'fr';
}

const LangWrapper = (props) => {
    const [locale, setLocale] = useState(local);

    const [messages, setMessages] = useState(lang);

    function selectLanguage(langCode) {
        const newLocale = langCode;
        setLocale(newLocale);
        if (newLocale === 'en') {
            setMessages(English);
        } else {
            if (newLocale === 'fr') {
                setMessages(French);
            } else {
                setMessages(French);
            }
        }
    }

    return (
        <LangContext.Provider value = {{locale, selectLanguage, messages}}>
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
        </LangContext.Provider>

    );
}


export default LangWrapper;