import { useData } from '@BASE/components/Dashboard/Pricing/data.hook';
import MobilePricing from '@BASE/components/Dashboard/Pricing/MobilePricing';
import { PrincingProvider } from '@BASE/components/Dashboard/Pricing/pricing.hook';
import PricingDurationSwitcher from '@BASE/components/Dashboard/Pricing/PricingDurationSwitcher';
import PricingPrimaryContent from '@BASE/components/Dashboard/Pricing/PricingPrimaryContent';
import { LangContext } from '@BASE/store/contexts/LangWrapper';
import { theme } from '@BASE/theme';
import { CheckCircleIcon } from '@chakra-ui/icons';
import {
  Box,
  ChakraBaseProvider,
  HStack,
  Stack,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import { featureSortForTable } from './packsFeature';
import TableComponent from './TableComponent';
import { useLoaderContext } from '@BASE/store/contexts/loader';

const BASE_URL = import.meta.env.VITE_BACKEND_BASE_URL;
function Pricing() {
  const [loading, setLoading] = useState(false);
  const { setLoader } = useLoaderContext();
  const { pricingTimeDurations } = useData();
  const [featuresDetails, setFeaturesDetails] = useState([]);
  const [packs, setPacks] = useState([]);
  const [pricingDurationChecked, setPricingDurationChecked] = useState(
    pricingTimeDurations[1].name
  );
  const langContext = useContext(LangContext);

  const getPacks = useCallback(async () => {
    setLoading(true);
    setLoader(true);
    const response = await axios.get(`${BASE_URL}/packs`);
    setPacks(response.data.data);
    setFeaturesDetails(
      featureSortForTable(response.data.data, langContext.locale)
    );
    setLoading(false);
    setLoader(false);
  }, [langContext.locale, setLoader]);

  useEffect(() => {
    getPacks();
  }, [getPacks]);

  return (
    <ChakraBaseProvider theme={theme}>
      <PrincingProvider>
        <Stack spacing="50px" bg="bgPrimary.500" mb={10} w="100%">
          <Box mt={32} display="flex" flexDirection="column" gap={2}>
            <Box
              width="100%"
              height="100%"
              display={{ base: 'none', md: 'flex' }}
              pr={2}
              mt={10}
              justifyContent="space-around"
              alignItems="center"
              gap="50px"
            >
              <Box width="30%" display="flex">
                {pricingTimeDurations.map((type) => (
                  <PricingDurationSwitcher
                    key={type.name}
                    pricingTimeDuration={type.name}
                    isChecked={pricingDurationChecked === type.name}
                    pricingTimeDurationReal={pricingDurationChecked}
                    onCheck={setPricingDurationChecked}
                  />
                ))}
              </Box>
            </Box>
          </Box>

          {/* Desktop pricing part... */}
          <Stack
            rounded="lg"
            boxShadow=" 1px 2px 16px 2px rgba(75,228,13,0.5);"
            alignItems="center"
            w={{ md: 'auto', lg: '100%' }}
            m="auto"
            bg="bgPrimary.500"
            spacing="100px"
            display={{ base: 'none', md: 'flex' }}
          >
            <VStack
              width={{ base: '100%', md: '100%' }}
              spacing={0}
              divider={<StackDivider borderColor="gray.100/20" />}
            >
              {/* First line */}
              <HStack
                divider={<StackDivider borderColor="gray.100/20" />}
                justifyContent="space-between"
                width="100%"
                bgColor="bgPrimary.500"
                zIndex={2}
              >
                {packs?.map((pack) => (
                  <Box key={pack?.name} width="30%">
                    <PricingPrimaryContent
                      pricingType={pack?.name}
                      price={pack?.price_month}
                      yearPercents={
                        pricingDurationChecked !==
                          pricingTimeDurations[0].name && pack?.percent_year
                      }
                      lookupkey={pack.lookup_key}
                    />
                  </Box>
                ))}
              </HStack>
              {/* Second line */}
              <HStack
                divider={<StackDivider m={0} borderColor="gray.100/20" />}
                justifyContent="space-between"
                alignItems="start"
                width="100%"
                zIndex={1}
              >
                {packs.map((feat, idx) => (
                  <Box
                    key={feat?.name}
                    width="30%"
                    display="flex"
                    pl={idx === 0 ? 6 : 0}
                    gap={2}
                    flexDirection="column"
                    justifyContent="start"
                    alignItems="start"
                    py={5}
                  >
                    {feat.description?.map((desc) => (
                      <HStack key={desc.en}>
                        <CheckCircleIcon color="brand.500" />
                        <Text>
                          {langContext.locale === 'en' ? desc.en : desc.fr}
                        </Text>
                      </HStack>
                    ))}
                  </Box>
                ))}
              </HStack>
            </VStack>
          </Stack>

          {/* Mobile pricing part... */}
          <Box
            display={{ base: 'flex', md: 'none' }}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            width="100%"
            gap="60px"
            px={5}
          >
            <Box
              display="flex"
              flexDirection="column"
              w="auto"
              border="1px"
              p={5}
              gap="10px"
              rounded="md"
            >
              {pricingTimeDurations.map((type) => (
                <PricingDurationSwitcher
                  key={type.name}
                  pricingTimeDuration={type.name}
                  isChecked={pricingDurationChecked === type.name}
                  pricingTimeDurationReal={pricingDurationChecked}
                  onCheck={setPricingDurationChecked}
                />
              ))}
            </Box>
            <MobilePricing packs={packs} priceValue={pricingDurationChecked} />
          </Box>
        </Stack>
        <Stack
          rounded="lg"
          boxShadow=" 1px 2px 16px 2px rgba(75,228,13,0.5);"
          alignItems="center"
          w={{ md: 'auto', lg: '100%' }}
          m={{ base: 10, md: 'auto' }}
          bg="bgPrimary.500"
          mb={5}
          px={{ base: '12px', md: 0 }}
        >
          <TableComponent featureInfo={featuresDetails} packs={packs} />
        </Stack>
      </PrincingProvider>
    </ChakraBaseProvider>
  );
}

export default Pricing;
