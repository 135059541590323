import { Button, Heading, Text, VStack } from "@chakra-ui/react";
import { usePricing } from "./pricing.hook";
import { useIntl } from "react-intl";
function PricingPrimaryContent({
  pricingType = "Starter",
  price = 0,
  priceCurrency = "$",
  yearPercents = 0,
  lookupkey,
}) {
  const { onCheckout } = usePricing();
  const intl = useIntl();
  return (
    <VStack justifyContent="space-between" height="auto" py={5} px={2}>
      <Heading as="h1" size="xl">
        {pricingType}
      </Heading>
      <VStack alignItems="center">
        <Heading as="h2" size="3xl" color={"brand.500"}>
          {priceCurrency}
          {!yearPercents ? price : price - (price * yearPercents) / 100}
        </Heading>
        <Text fontWeight="bold">
          {intl.formatMessage({ id: `Pricing.duration.text` })}
        </Text>
      </VStack>
      {yearPercents && (
        <VStack alignItems={"center"} fontWeight="bold">
          <Text fontSize="3xl" textDecoration="line-through" color="red.500">
            {priceCurrency}
            {price}
          </Text>
          <Heading
            fontSize="3xl"
            sx={{
              fontWeight: "bold",
            }}
          >
            {yearPercents} %{" "}
            {intl.formatMessage({ id: `Pricing.reduction.text` })}
          </Heading>
        </VStack>
      )}
      <Button
        colorScheme="brand"
        color="black"
        variant="solid"
        mt={10}
        p={10}
        size="2xl"
        onClick={() => onCheckout(lookupkey)}
      >
        {intl.formatMessage({ id: `Pricing.select.button` })}
      </Button>
    </VStack>
  );
}

export default PricingPrimaryContent;
