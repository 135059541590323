import jwt from 'jwt-decode';
import CryptoJS from 'crypto-js';
import { client } from '@BASE/utils/client';

const storageKey = import.meta.env.VITE_ACCESS_TOKEN_STORAGE_KEY;

export const encryptData = (data) => {
  const encrypted = CryptoJS.AES.encrypt(
    data,
    import.meta.env.VITE_ACCESS_TOKEN_CRYPT_KEY
  ).toString();
  // console.log('encrypted', encrypted)
  return encrypted;
};

const decryptData = (data) => {
  const encrypted = data;
  const decrypted = CryptoJS.AES.decrypt(
    encrypted,
    import.meta.env.VITE_ACCESS_TOKEN_CRYPT_KEY
  ).toString(CryptoJS.enc.Utf8);
  // console.log('decrypted', decrypted)
  return decrypted;
};

export const getToken = () => {
  return decryptData(
    localStorage.getItem(storageKey) ||
      sessionStorage.getItem(storageKey) ||
      'no-token'
  );
};

export const removeToken = () => {
  localStorage.removeItem(storageKey);
  sessionStorage.removeItem(storageKey);
};

export const tokenExists = () => {
  return localStorage.getItem(storageKey) && getToken() !== 'no-token';
};

export const isTokenExpired = () => {
  if (tokenExists()) {
    const token = getToken();
    const decodedToken = jwt(token);
    const dateNow = new Date();
    const dateNowTimestamp = dateNow.getTime() / 1000;
    // console.log(decodedToken.exp, dateNowTimestamp)
    return decodedToken.exp < dateNowTimestamp;
  }
  return true;
};

export const getTokenData = () => {
  if (tokenExists()) return jwt(getToken());
  return null;
};

export const userInfosExists = () => {
  return (localStorage.getItem('user-infos') || 'no-infos') !== 'no-infos';
};

export const getUserInfos = () => {
  if (userInfosExists()) return JSON.parse(localStorage.getItem('user-infos'));
  return null;
};

export const getTokenEmail = () => {
  if (userInfosExists()) return getUserInfos()._email;
  return 'no-email';
};

export const getTokenEntireName = () => {
  if (userInfosExists())
    return getUserInfos()._userFirstname + ' ' + getUserInfos()._userLastname;
  return 'John Doe';
};
export const getTokenFirstName = () => {
  if (userInfosExists()) return getUserInfos()._userFirstname;
  return 'John';
};
export const getTokenLastName = () => {
  if (userInfosExists()) return getUserInfos()._userLastname;
  return 'Doe';
};

export const getTokenCompanyName = () => {
  if (userInfosExists()) return getUserInfos()._companyName;
  return 'Doe';
};
export const getTokenPicture = () => {
  if (userInfosExists()) return getUserInfos()._picture;
  return '';
};

export const getTokenNumberOfConnections = () => {
  if (userInfosExists()) return getUserInfos()._numberOfTimesUserConnected;
  return 0;
};

export const getTokenContext = () => {
  if (userInfosExists()) return getUserInfos()._contexts;
  return 'no-context';
};
export const getTokenContextTest = () => {
  if (userInfosExists()) return getUserInfos()._contexts_test;
  return 'no-context';
};

export const getTokenAssistantName = () => {
  if (
    userInfosExists() &&
    getUserInfos()._assistantName !== undefined &&
    getUserInfos()._assistantName !== null
  )
    return getUserInfos()._assistantName;
  return 'AIBOOSTEZ';
};

export const getIsUserConnectedToLinkedIn = () => {
  if (
    userInfosExists() &&
    getUserInfos()._linkedin_token_info !== undefined &&
    getUserInfos()._linkedin_token_info !== null &&
    Object.keys(getUserInfos()._linkedin_token_info).length > 0
  ) {
    return true;
  }
  return false;
};

export const doLogout = async (
  navigate,
  toastState,
  setToastState,
  setLoader,
  intl
) => {
  setLoader(true);
  try {
    removeToken();
    localStorage.removeItem('user-infos');
    localStorage.removeItem('keep-me');
    setLoader(false);
    navigate('/', { replace: true });
  } catch (err) {
    console.log(err);
    setToastState({
      ...toastState,
      severity: 'error',
      message: intl.formatMessage({ id: 'Toast.message.error' }),
      open: true,
      duration: 5000,
    });
  }
  setLoader(false);
};
