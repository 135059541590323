import { Box, Button, Typography } from '@mui/material';
import { useToastContext } from '@BASE/store/contexts';
import PaymentsIcon from '@mui/icons-material/Payments';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

const ReferralPriceCard = ({ amount }) => {
  const intl = useIntl();
  const { showToast } = useToastContext();
  return (
    <Box
      // mx={'auto'}
      p={2}
      sx={{
        width: 2 / 4,
        minWidth: '300px',
        maxWidth: '500px',
        border: 1,
        borderColor: 'primary.main',
        borderRadius: '12px',
        boxShadow: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        height: '200px',
      }}
    >
      <Typography
        variant="h2"
        gutterBottom
        color={'primary.main'}
        fontWeight={'bold'}
      >
        {Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          useGrouping: false,
        }).format(amount)}
      </Typography>
      <Typography variant="body1" gutterBottom color={'#dfd6d3'}>
        {intl.formatMessage({ id: 'dash.referral.stats.reward.text' })}
      </Typography>
      <Link
        to={
          amount >= 100
            ? `mailto:contact.us@aiboostez.com?subject=Request amount of $${amount}`
            : '#'
        }
        onClick={() => {
          amount < 100 &&
            showToast({
              severity: 'error',
              message: intl.formatMessage({ id: 'dash.referral.reward.error' }),
            });
        }}
      >
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<PaymentsIcon />}
          sx={{
            width: '100%',
            py: 1.5,
            fontWeight: 'bold',
            borderRadius: '35px',
          }}
        >
          {intl.formatMessage({ id: 'dash.referral.stats.reward.button' })}
        </Button>
      </Link>
    </Box>
  );
};

export default ReferralPriceCard;
