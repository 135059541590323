import { useIntl } from 'react-intl';

// material-ui
import { Box, Typography } from '@mui/material';

// project import
import menuItem from '@BASE/menu-items';
import NavCollapse from './NavCollapse';
import NavGroup from './NavGroup';
import NavItem from './NavItem';
import { useSelector } from 'react-redux';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const intl = useIntl();
  const { sections } = useSelector((state) => state.auth);
  const getTitle = (id) => {
    if (intl.messages[id] !== undefined) {
      return intl.formatMessage({ id });
    } else {
      return id;
    }
  };

  const getAuthorizedSections = () => {
    let newMenuItem = menuItem(getTitle).items.map((item) => {
      if (item.code && !sections.includes(item.code)) {
        return { ...item, notAllowed: true };
      }
      return item;
    });
    return newMenuItem;
  };

  const navGroups = getAuthorizedSections().map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      case 'collapse':
        return <NavCollapse key={item.id} item={item} />;
      case 'item':
        return <NavItem key={item.id} item={item} level={0.6} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
