import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AuthorizedRoute = ({ children, code }) => {
  const { sections } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const checkIfRouteAuthorized = useCallback(() => {
    if (!sections.includes(code)) {
      navigate('/dashboard');
    }
  }, [code, navigate, sections]);

  useEffect(() => {
    checkIfRouteAuthorized();
  }, [checkIfRouteAuthorized, sections]);

  return <>{children}</>;
};
export default AuthorizedRoute;
