import { createContext, useContext, useState } from 'react';

const ToastContext = createContext();

export const ToastContextProvider = ({ children }) => {
    const [toastState, setToastState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'right',
        message: 'This functionality is not available yet.',
        severity: 'info',
        duration: 5000,
    });

    const showToast = (props) => {
        setToastState(prev => ({ ...prev, ...props, open: true }));
    }

    const [popupOpen, setPopupOpen] = useState(false);
    const [popupContent, setPopupContent] = useState({});
        

  return (
    <ToastContext.Provider value={{toastState, setToastState, showToast, popupOpen, setPopupOpen,
    popupContent, setPopupContent }}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToastContext = () => useContext(ToastContext);