import { createContext, useContext, useState } from 'react';

const UpgradeContext = createContext();

export const UpgradeContextProvider = ({ children }) => {
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
    const [upgradeMessage, setUpgradeMessage] = useState("");

  return (
    <UpgradeContext.Provider value={{openUpgradeDialog, setOpenUpgradeDialog, upgradeMessage, setUpgradeMessage }}>
      {children}
    </UpgradeContext.Provider>
  );
};

export const useUpgradeContext = () => useContext(UpgradeContext);