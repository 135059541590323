// assets
import { ChromeOutlined, DashboardOutlined } from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined,
  ChromeOutlined
};

// ==============================|| MENU ITEMS - AUTOMATISATION ||============================== //


const panels = (getTitle) => ({
  id: 'group-ecom-master',
  code: 'ECOMMASTER',
  title: getTitle("Dash.Menu.ecom"),
  icon: icons.ChromeOutlined,
  type: 'collapse',
  children: [
    {
      id: 'ecom-1',
      title: getTitle("Dash.Menu.ecom.adcopy"),
      type: 'item',
      url: '/dashboard/ecom/1',
      icon: icons.DashboardOutlined,
      breadcrumbs: true
    },
    {
        id: 'ecom-2',
        title: getTitle("Dash.Menu.ecom.pain_gain"),
        type: 'item',
        url: '/dashboard/ecom/2',
        icon: icons.DashboardOutlined,
        breadcrumbs: true
      },
      {
        id: 'ecom-3',
        title: getTitle("Dash.Menu.ecom.mark_angles"),
        type: 'item',
        url: '/dashboard/ecom/3',
        icon: icons.DashboardOutlined,
        breadcrumbs: true
      },
      {
        id: 'ecom-4',
        title: getTitle("Dash.Menu.ecom.faq_headlines"),
        type: 'item',
        url: '/dashboard/ecom/4',
        icon: icons.DashboardOutlined,
        breadcrumbs: true
      },
      {
        id: 'ecom-5',
        title: getTitle("Dash.Menu.ecom.customer_reviews"),
        type: 'item',
        url: '/dashboard/ecom/5',
        icon: icons.DashboardOutlined,
        breadcrumbs: true
      },
      {
        id: 'ecom-6',
        title: getTitle("Dash.Menu.ecom.email_mark"),
        type: 'item',
        url: '/dashboard/ecom/6',
        icon: icons.DashboardOutlined,
        breadcrumbs: true
      },
      {
        id: 'ecom-7',
        title: getTitle("Dash.Menu.ecom.sms_mark"),
        type: 'item',
        url: '/dashboard/ecom/7',
        icon: icons.DashboardOutlined,
        breadcrumbs: true
      },

    {
        id: 'ecom-8',
        title: getTitle("Dash.Menu.ecom.product_page"),
        type: 'item',
        url: '/dashboard/ecom/8',
        icon: icons.DashboardOutlined,
        breadcrumbs: true
      },
      {
        id: 'ecom-9',
        title: getTitle("Dash.Menu.ecom.contracts"),
        type: 'item',
        url: '/dashboard/ecom/9',
        icon: icons.DashboardOutlined,
        breadcrumbs: true
      },
  ]
});

export default panels;
