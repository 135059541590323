// assets
import {
  DollarOutlined,
  DashboardOutlined,
  HomeOutlined,
  ScheduleOutlined,
  EditOutlined,
} from '@ant-design/icons';
import BarChartIcon from '@mui/icons-material/BarChart';

// icons

// project import
import automation from './automation';
import contents from './contents';
import ecom_master from './ecom_master';
import marketing from './marketing';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = (getTitle) => ({
  items: [
    {
      id: 'home',
      title: getTitle('Dash.Menu.home'),
      type: 'item',
      url: '/dashboard',
      icon: HomeOutlined,
      breadcrumbs: true,
    },
    {
      id: 'context',
      title: getTitle('Dash.Menu.context'),
      type: 'item',
      url: '/dashboard/context',
      icon: EditOutlined,
      breadcrumbs: true,
    },

    {
      id: 'prospection',
      code: 'PROSPECTION',
      title: getTitle('Dash.Menu.prospection'),
      icon: DashboardOutlined,
      type: 'item',
      url: '/dashboard/prospection',
      breadcrumbs: true,
    },
    marketing(getTitle),
    contents(getTitle),
    {
      id: 'analysis',
      code: 'ANALYSES',
      title: getTitle('Dash.Menu.analysis'),
      type: 'item',
      url: '/dashboard/analysis',
      icon: BarChartIcon,
      breadcrumbs: true,
    },
    {
      id: 'referral',
      code: 'REFERRAL',
      title: getTitle('Dash.Menu.referral'),
      type: 'item',
      url: '/dashboard/referral',
      icon: DollarOutlined,
      breadcrumbs: true,
    },
    {
      id: 'to-come',
      title: getTitle('Dash.Menu.upcoming_features'),
      type: 'group',
      breadcrumbs: true,
    },
    {
      id: 'scheduling',
      code: 'SCHEDULING',
      title: getTitle('Dash.Menu.scheduling'),
      type: 'item',
      url: '/dashboard/scheduling',
      icon: ScheduleOutlined,
      breadcrumbs: true,
    },
    {
      code: 'EREPUTATION',
      id: 'e-reputation',
      title: getTitle('Dash.Menu.ereputation'),
      type: 'item',
      url: '/dashboard/e-reputation',
      icon: DashboardOutlined,
      breadcrumbs: true,
    },
    {
      code: 'EmailAccounts',
      id: 'email-accounts',
      title: getTitle('Dash.Menu.emailaccounts'),
      type: 'item',
      url: '/dashboard/email-accounts',
      icon: DashboardOutlined,
      breadcrumbs: true,
    },
    {
      id: "campaigns",
      code: "CAMPAIGNS",
      title: getTitle("Dash.Menu.campaigns"),
      type: "item",
      url: "/dashboard/campaigns",
      icon: DashboardOutlined,
      breadcrumbs: true,
    },
    {
      id: 'prospects',
      code: 'PROSPECTS',
      title: getTitle('Dash.Menu.prospects'),
      type: 'item',
      url: '/dashboard/prospects',
      icon: DashboardOutlined,
      breadcrumbs: true,
    },
    automation(getTitle),
    ecom_master(getTitle),
    // {
    //   id: "test",
    //   title: getTitle("Dash.Menu.test"),
    //   type: "item",
    //   url: "/dashboard/test-panel",
    //   icon: ChromeOutlined,
    //   breadcrumbs: true,
    // },
  ],
});

export default menuItems;
